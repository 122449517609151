<p-dialog [(visible)]="mostrarModal" [draggable]="false" [resizable]="false" [modal]="true" [dismissableMask]="true"
    class="bs-modal w-500">
    <p-tabView>
        <p-tabPanel header="{{titulo}}">
            <ng-template pTemplate="content">
                <p class="bs-center" style="padding-top: 20px;">
                    <strong class="bs-negro">{{mensaje}} </strong>
                </p>
        
                <div class="bs-separador h-10"></div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>  

    <ng-template pTemplate="footer">
        <div class="simple">
            <button class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-secondary" type="reset"
                [ngClass]="{'bs-btn-error': tipo == 'error', 'bs-btn-verde': tipo != 'error'}"
                (click)="onCancel()">Cerrar</button>

                <button *ngIf="onSuccess" style="visibility: hidden;" class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-primary" >Aceptar</button>

                <button *ngIf="onSuccess" class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-primary" type="button"
                [ngClass]="{'bs-btn-error': tipo == 'error', 'bs-btn-verde': tipo != 'error'}"
                (click)="exito()">Aceptar</button>
        </div>
    </ng-template>
</p-dialog>