|<div class="bs-contenedor" style="min-width: 90%;">
    <div class="bs-pestanas">
        <p-tabView>
            <p-tabPanel header="Convenios Zenit">
                <ng-template pTemplate="content">
                    <div class="bs-separador h-30"></div>
                    <div class="col-12">
                        <div class="bs-tabla-datos">
                            <p-table [value]="elementos_tabla_convenios_pines"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} datos"
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th id="rut" rowspan="2" width="110" pSortableColumn="rut" scope="col">
                                            <span>Rut Corredor</span>
                                            <p-sortIcon field="rut"></p-sortIcon>
                                        </th>
                                        <th id="nombre" rowspan="2" width="110" pSortableColumn="nombre" scope="col">
                                            <span>Nombre Corredor</span>
                                            <p-sortIcon field="nombre"></p-sortIcon>
                                        </th>
                                        <th id="nombreConvenio" rowspan="2" width="110" pSortableColumn="nombreConvenio" scope="col">
                                            <span>Nombre Convenio</span>
                                            <p-sortIcon field="nombreConvenio"></p-sortIcon>
                                        </th>
                                        <th id="fechaCreacion" rowspan="2" width="110" pSortableColumn="fechaCreacion" scope="col">
                                            <span>Fecha Creación</span>
                                            <p-sortIcon field="fechaCreacion"></p-sortIcon>
                                        </th>
                                        <th id="fechaModificacion" rowspan="2" width="110" pSortableColumn="fechaModificacion" scope="col">
                                            <span>Fecha Modificación</span>
                                            <p-sortIcon field="fechaModificacion"></p-sortIcon>
                                        </th>
                                        <th id="rutUsuarioCreador" rowspan="2" width="110" pSortableColumn="rutUsuarioCreador" scope="col">
                                            <span>Rut Creador Convenio</span>
                                            <p-sortIcon field="rutUsuarioCreador"></p-sortIcon>
                                        </th>
                                        <th id="recursoValor" rowspan="2" width="110" pSortableColumn="recursoValor" scope="col">
                                            <span>Descuentos</span>
                                            <p-sortIcon field="recursoValor"></p-sortIcon>
                                        </th>
                                        <th id="habilitaPines" rowspan="2" width="110" pSortableColumn="habilitaPines" scope="col">
                                            <span>Habilita Pines</span>
                                            <p-sortIcon field="habilitaPines"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="20%" pSortableColumn="etiqueta" scope="col">
                                            <span>Acción</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-i="index">
                                    <tr>
                                        <td>{{ data.rut }}</td>
                                        <td>{{ data.nombre }}</td>
                                        <td>{{ data.nombreConvenio }}</td>
                                        <td>{{ data.fechaCreacion | dateFormat }}</td>
                                        <td>{{ data.fechaModificacion | dateFormat }}</td>
                                        <td>{{ data.rutUsuarioCreador }}</td>
                                        <td>{{ data.recursoValor }}</td>
                                        <td>{{ siNo(data.habilitaPines, 'No', 'Sí')}}</td>
                                        <td>
                                            <div class="bs-tabla-panel row" style="text-align: center;width: 100%;">                                              
                                                <div>
                                                    <button class="bs-btn bs-btn-secondary tabla"
                                                    (click)="mostrarEditar(data)">
                                                        Editar Descuentos
                                                    </button>
                                                </div> 
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<app-editar-descuento (alCerrarModal)="obtenerConveniosPines()" [convenioSeleccionado]="convenioSeleccionado" [(abrirModal)]="mostrarModalEditar" *ngIf="mostrarModalEditar"></app-editar-descuento>

