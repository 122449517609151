import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { ComponentesComponent } from './modules/layout/components/componentes/componentes.component';
import { ComponentsModule } from './components.module';



@NgModule({
  declarations: [
    ComponentesComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    ComponentsModule
  ]
})
export class ContainersModule { }
