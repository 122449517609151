import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

let CryptoJS = require('crypto-js');

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public keyMaster: string = environment.KEY_SESION;

  Set(key: string, value: any): any {
    let keyCrypt = CryptoJS.SHA256(key, this.keyMaster);
    let data = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      this.keyMaster
    ).toString();

    localStorage.removeItem(keyCrypt);
    localStorage.setItem(keyCrypt, data);
  }

  Get(key: string): any {
    let keyCrypt = CryptoJS.SHA256(key, this.keyMaster);
    let data = localStorage.getItem(keyCrypt);

    if (data != null) {
      data = CryptoJS.AES.decrypt(data, this.keyMaster).toString(
        CryptoJS.enc.Utf8
      );

      if (data != null) return JSON.parse(data);
    }
  }

  Delete(key: string) {
    let keyCrypt = CryptoJS.SHA256(key, this.keyMaster);
    localStorage.removeItem(keyCrypt);
  }
}
