import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { SesionService } from '../services/sesion.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  public relogueandose: boolean = false;

  constructor(private authService: SesionService, private loginService: LoginService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.EstaLogueado()) {
      return from(this.tokenHandler(request, next));
    }
    this.relogueandose = false;
    return next.handle(request);
  }

  async tokenHandler(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Promise<HttpEvent<any>> {

    let sesion = this.authService.ObtenerSesion();
    if (new Date().getTime() > new Date(sesion.fechaVencimientoToken).getTime() && !this.relogueandose) {
      this.relogueandose = true;
      let nuevaSesion = await this.loginService.LoginOv(sesion.rut, sesion.sesionId);
      this.authService.GuardarSesion(nuevaSesion);
      sesion = this.authService.ObtenerSesion();
    }

    const token = sesion.token;

    const httpHeaders = new HttpHeaders()
      .set('Authorization', `bearer ${token}`);

    req = req.clone({
      headers: httpHeaders
    });


    return (next.handle(req)).toPromise();
  }
}
