<div class="bs-separador h-30"></div>

<div class="bs-contenedor" style="min-width: 90%;">
    <!-- <div class="row">
        <div class="col-12">
            <span class="bs-chip">Pines asignados cotizadores</span>
            <div class="bs-separador h-30"></div>
        </div>
    </div> -->

    <div class="bs-pestanas">
        <p-tabView>
            <p-tabPanel header="Pines Asignados">
                <ng-template pTemplate="content">
                    <div class="bs-separador h-30"></div>
                    <div class="col-12">
                        <div class="bs-tabla-datos">
                            <p-table [value]="elementos_tabla_asignados"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} datos"
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                                            <span>Usuario</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="110" pSortableColumn="cantidad" scope="col">
                                            <span>Sitio Web</span>
                                            <p-sortIcon field="cantidad"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                                            <span>Rut</span>
                                        <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                                            <span>Fecha Asignación</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="140" pSortableColumn="etiqueta" scope="col">
                                            <span>Descuento</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="140" pSortableColumn="etiqueta" scope="col">
                                            <span>Pin</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-i="index">
                                    <tr>
                                        <td>{{ data.usuario }}</td>
                                        <td>{{ data.sitioWeb }}</td>
                                        <td>{{ data.rut }}</td>
                                        <td>{{ data.fechaAsignacion }}</td>
                                        <td>{{ data.descuento }}</td>
                                        <!-- <td>{{ data.pin }}</td> -->
                                        <td>
                                            <div class="bs-tabla-panel" style="text-align: center;width: 100%;">
                                                <div style="width: 100%;">
                                                    <button *ngIf="!data.mostrarPin" class="bs-btn bs-btn-primary tabla"
                                                        (click)="mostrar_modal_asignar(data.idSolicitudCreacionPines)">
                                                        Ver pin
                                                    </button>
                                                    <div *ngIf="data.mostrarPin">{{ data.pin }}</div>
                                                </div>

                                                <!--<a class="bs-btn bs-btn-secondary tabla"> Desactivar </a>-->
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>


                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>

    <p-dialog [(visible)]="modal_mostrar" [draggable]="false" [resizable]="false" [modal]="true"
        [dismissableMask]="true" class="bs-modal w-500">
        <ng-template pTemplate="header">
            <h2 class="bs-azul bs-center">
                <strong>{{modal_msj_principal}}</strong>
            </h2>
        </ng-template>
        <ng-template pTemplate="content">
            <p class="bs-center">
                <strong class="bs-negro">{{modal_msj_secundario}} </strong>
            </p>

            <div class="bs-separador h-10"></div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="simple">
                <button class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-primary" type="reset"
                    [ngClass]="{'bs-btn-error': error_carga_inicial, 'bs-btn-verde': !error_carga_inicial}"
                    (click)="aceptar_modal()">{{modal_boton_nombre}}</button>
            </div>
        </ng-template>
    </p-dialog>
</div>