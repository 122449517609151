import { Component, OnInit } from '@angular/core';
import { SesionModel } from 'src/app/model/sesion.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConvenioPinesService as ConvenioPinesService } from 'src/app/services/convenio-pines.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-convenio-pines',
  templateUrl: './convenio-pines.component.html',
  styleUrls: ['./convenio-pines.component.css']
})
export class ConvenioPinesComponent implements OnInit {

  public elementos_tabla_convenios_pines: any[] = [];
  public sesion: SesionModel;
  public convenioSeleccionado: number = 0;
  public mostrarModalEditar: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private ConvenioPinesService: ConvenioPinesService,
    private loaderService: LoaderService,
    private sesionService: SesionService
  ) { }

  ngOnInit(): void {
    this.loaderService.show();
    this.sesion = this.sesionService.ObtenerSesion();
    this.obtenerConveniosPines();
    this.loaderService.close();
  }

  async obtenerConveniosPines() {
    let listado_Convenio_pines = await this.ConvenioPinesService.ObtenerConveniosPinesZenit();
    this.elementos_tabla_convenios_pines = listado_Convenio_pines.lst_Convenios
    console.log(this.elementos_tabla_convenios_pines);

  }

  public siNo(x: number, no: string, si: string): string {
    if (x === 0) {
      return no;
    } else {
      return si;
    }
  }

  mostrarEditar(pin: any) {
    this.convenioSeleccionado = pin;
    this.mostrarModalEditar = true;
  }


}
