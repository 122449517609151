import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
@Injectable({
  providedIn: 'root'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, format: string = 'dd-MM-yyyy'): any {
    if (value) {
      const datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, format);
    }
    return value;
  }
}