import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { format } from '../utils/rut.util';

@Pipe({
  name: 'formatRut',
})
@Injectable({
  providedIn: 'root'
})
export class FormatRutPipe implements PipeTransform {
  transform(rut: string): string {
    return format(rut, { dots: true });
  }
}
