import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BolsaPinesServiceService } from 'src/app/services/bolsa-pines-service.service';
import { ComplementosServiceService } from 'src/app/services/complementos-service.service';
import { ConvenioService } from 'src/app/services/convenio.service';
import { PinesServiceService } from 'src/app/services/pines-service.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-derivar-pin',
  templateUrl: './derivar-pin.component.html',
  styleUrls: ['./derivar-pin.component.css']
})
export class DerivarPinComponent implements OnInit {
  public elementos_tabla_derivados: any[] = [];
  public modal_mostrar: boolean = false;
  public modal_derivar: boolean = false;

  public modal_msj_principal: string = 'mensaje principal';
  public modal_msj_secundario: string = 'mensaje secundario';
  public modal_boton_nombre: string = 'nombre btn';
  public error_carga_inicial: boolean = false;
  public tipo_accion: string = '';
  registerForm!: FormGroup;
  public tipo_datos: string = '';
  public datos_cantidad: any[] = [];
  public tipo_cantidad: any;
  public idPin: number = 0;
  public idBolsa: number = 0;
  public cantidad: number = 0;
  public descuento: number = 0;
  public idBolsaActual?: number;
  public idConvenioActual?: any;
  public descuentoActual?: number; 
  public cantidadActual: any; 
  submitted = false;
  public datos: any[] = [];
  public modal_derivar_convenio: boolean = false;
  public convenios: any[] = [];
  public listPinesDerivarGrid: any = {};
  public listTipoAccion: any[] = [];
  public usuarios_comerciales: any[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private serviceBolsaPines: BolsaPinesServiceService,
    private complementosService: ComplementosServiceService,
    private servicePines: PinesServiceService,
    private sesionService: SesionService,
    private convenioService: ConvenioService
  ) {
    this.registerForm = this.initValidatorsForm();
  }

  ngOnInit(): void {
    this.obtenerAcciones()
    this.configuracionGridPinesDerivar();
    this.obtenerUsuariosComerciales();
    this.obtenerConvenios();
  }

  obtenerAcciones() {
    this.error_carga_inicial = true;
    this.complementosService.getAcciones().subscribe({
      next: (response) => {
        for (let item of response!.acciones) {
          let sistema = {
            id: item.idAccion,
            nombre: item.nombre
          }
          this.listTipoAccion.push(sistema);
          this.error_carga_inicial = false;
        }
      },
      error: (error) => {
        // this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con... 1', 'Aceptar');
        }
      }
    })
  }

  async obtenerConvenios(){
    let result = await this.convenioService.ObtenerConvenios(1);     
    this.convenios = result.convenios;
 }

  obtenerUsuariosComerciales() {
    this.error_carga_inicial = true;
    this.complementosService.getUsuariosComerciles().subscribe({
      next: (response) => {
        for (let item of response!.usuarios) {
          let usuarioComercial = {
            id: item.idUsuario,
            nombre: item.nombre
          }
          this.datos.push(usuarioComercial);
          this.usuarios_comerciales.push(item);
          this.error_carga_inicial = false;
        }
      },
      error: (error) => {
        // this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con...', 'Aceptar');
        }
      }
    })
  }

  initValidatorsForm(): FormGroup {
    return this.formBuilder.group({
      usuarioComercial: ['', Validators.required],
      cantidad: ['', Validators.required],
    })
  }

  mostrar_modal_derivar(botonAccion: string, idSolicitud: any, idBolsa: any, cantidad: any, descuento: any) {
    this.registerForm.reset();
    this.tipo_datos = '';
    this.tipo_cantidad = '';

    this.tipo_accion = botonAccion;
    this.idPin = idSolicitud;
    this.idBolsa = idBolsa;
    this.cantidad = cantidad;
    this.descuento = descuento;

    this.obtenerlistadoCantidad(cantidad, botonAccion);

    this.modal_derivar = true;
  }

  mostrar_modal_derivar_convenio(idBolsa: any, cantidad: any, descuento: any){
    this.modal_derivar_convenio = true;
    this.inicializarValores();
    this.obtenerlistadoCantidad(cantidad, 'AsignarConvenio');

    this.idBolsaActual = idBolsa;
    this.descuentoActual = descuento;
  }

  async aceptar_modal() {
    this.modal_mostrar = false;

  }

  async onSubmit() {
    this.submitted = true;
    //this.loader_html = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.modal_derivar = false;

     if(this.tipo_accion == 'Derivar'){
       await this.derivarPin();
     } 
     if(this.tipo_accion == 'Asignar'){
      console.log('Asigna')
      await this.asignarPin();
    }

     this.actualizarGrillas();
  }

  async asignarPin() {
    try {
      console.log('asignarPin')
      let form = this.registerForm.value;
      this.error_carga_inicial = true;
      let rut! : number;
      let dv! : string;

      for (let item of this.usuarios_comerciales) {
          if(item.idUsuario == form.usuarioComercial.id){
            rut = item.rut;
            dv = item.dv;
          }
      }

      let response = await this.servicePines.asignarPine(this.descuento,form.usuarioComercial.id, this.idBolsa, form.cantidad.id , rut, dv)
      console.log(response)
      this.initFormPin()
      if (response.estado != true) {
        this.error_carga_inicial = true;
        this.mostrar_modal('Derivación de Pin', response.mensaje, 'Aceptar');
      } else {
        this.error_carga_inicial = false;
        this.configuracionGridPinesDerivar();
        this.mostrar_modal('Derivación de Pin ok', 'Derivación de Pin Exitoso.', 'Aceptar');
        
      }
    } catch (error: any) {
      if (error.ok == false) {
        this.error_carga_inicial = true;
        this.mostrar_modal('Derivación de Pin', 'Error al intentar derivar pin. Favor de comunicarse con...', 'Aceptar');
      }

    }
  }

  get controlsFormErros() {
    return this.registerForm.controls;
  }

  async asignarPinesConvenio() {
    if (this.idConvenioActual && this.cantidadActual && this.idBolsaActual && this.descuentoActual) {
      let response = await this.servicePines.asignarPine(this.descuentoActual, this.sesionService.ObtenerSesion().idUsuario, this.idBolsaActual,parseInt(this.cantidadActual.id), 0, '',this.idConvenioActual.idInterno)
      this.initFormPin()
      if (response.estado != true) {
        this.error_carga_inicial = true;
        this.mostrar_modal('Asignación de Pin', response.mensaje, 'Aceptar');
      } else {
        this.error_carga_inicial = false;
        this.configuracionGridPinesDerivar();
        this.mostrar_modal('Asignación de Pin ok', 'Derivación de Pin Exitoso.', 'Aceptar');
        this.actualizarGrillas();        
      }

      this.modal_derivar_convenio = false;

      this.inicializarValores();
    }
  }

  obtenerlistadoCantidad(cantidad: number, botonAccion: string) {
    this.datos_cantidad = [];
    for (let x=1; x <= cantidad;x++) {
      if(x == 1 || (x > 1 && botonAccion != 'Asignar')){
        let cargaCantidad = {
          id: x,
          nombre: x
        }
        this.datos_cantidad.push(cargaCantidad);
      }
    }    
  }

  private inicializarValores(){
    this.idConvenioActual = undefined;
    // this.cantidadActual = undefined;
    this.idBolsaActual = undefined;
    this.descuentoActual = undefined;
  }

  async derivarPin() {
    try {
      console.log('derivarPin')
      let form = this.registerForm.value;
      this.error_carga_inicial = true;

      let response = await this.serviceBolsaPines.getDerivarPin(this.idPin, form.cantidad.id, this.descuento, form.usuarioComercial.id, this.idBolsa)
      console.log(response)
      this.initFormPin()
      if (response.estado != true) {
        this.error_carga_inicial = true;
        this.mostrar_modal('Derivación de Pin', response.mensaje, 'Aceptar');
      } else {
        this.error_carga_inicial = false;
        this.configuracionGridPinesDerivar();
        this.mostrar_modal('Derivación de Pin', 'Derivación de Pin Exitoso.', 'Aceptar');
        
      }
    } catch (error: any) {
      if (error.ok == false) {
        this.error_carga_inicial = true;
        this.mostrar_modal('Derivación de Pin', 'Error al intentar derivar pin. Favor de comunicarse con...', 'Aceptar');
      }

    }
  }

  actualizarGrillas(){
    this.configuracionGridPinesDerivar();
  }

  configuracionGridPinesDerivar() {
    this.error_carga_inicial = true;
    let UsuarioLogueado=this.sesionService.ObtenerSesion();
    this.serviceBolsaPines.getBolsaPinesPorUsuario(UsuarioLogueado.idUsuario, 1).subscribe({
      next: (response) => {
        if (response.estado == true) {
          this.listPinesDerivarGrid = response.bolsasPinesAsignada;
          this.generarListaDerivarPines();
        }

        // this.loader_html = false;
        this.error_carga_inicial = false;
      },
      error: (error) => {
        // this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con...', 'Aceptar');
        }
      }
    });
  }

  initFormPin() {
    this.submitted = false;
    this.registerForm.reset();

    this.error_carga_inicial = false;
    // this.loader_html = false;
    this.modal_mostrar = false;

    this.tipo_datos = '';
  }

  mostrar_modal(msjPrincipal: string, msjSecundario: string, nombreBoton: string) {
    this.modal_msj_principal = msjPrincipal;
    this.modal_msj_secundario = msjSecundario;
    this.modal_boton_nombre = nombreBoton;

    this.modal_mostrar = true;
  }

  generarListaDerivarPines() {
    this.elementos_tabla_derivados = [];
    for (let elem of this.listPinesDerivarGrid) {
      let pinesDerivar = {
        idSolicitudCreacionPines: elem.idSolicitudCreacionPines,
        idBolsaDistribucionPines: elem.idBolsaDistribucionPines,
        usuario: elem.nombreUsuario,
        rut: elem.rutUsuario,
        sitioWeb: elem.sistema,
        cantidad: elem.cantidad,
        descuento: elem.descuento,
        vigenciaDesde: this.formatoFecha(new Date(elem.vigencia_desde)),
        vigenciaHasta: this.formatoFecha(new Date(elem.vigencia_hasta)),
        esDerivar: this.validarExistenciaTipoAccion(elem.idAccion, 'derivar'),
        esAsignar: this.validarExistenciaTipoAccion(elem.idAccion, 'asignar'),
        esDerivarPirConvenio:  this.validarExistenciaTipoAccion(elem.idAccion, 'Descuento_Por_Convenio'),
        accionNombre: elem.accionNombre
      }

      this.validarExistenciaTipoAccion(elem.idAccion, 'asignar')
      this.elementos_tabla_derivados.push(pinesDerivar);
    }
  }

  formatoFecha(fecha: Date) {
    const dia = ("0" + fecha.getDate()).slice(-2);
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    return (dia + '-' + mes + '-' + fecha.getFullYear());
  }

  validarExistenciaTipoAccion(idAccion: number, nombreAccion: string)
  {
    for(let x=0; this.listTipoAccion.length > x; x++){
      
      if(this.listTipoAccion[x].id == idAccion && this.listTipoAccion[x].nombre.toLowerCase().includes(nombreAccion.toLowerCase())){
          return true;
      }
    }
    return false;
  }
}
