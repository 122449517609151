import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { ModalComponent } from '../shared/components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private componentRef!: ComponentRef<ModalComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) { }

  alert(mensaje: string, titulo: string = "Información", tipo: string = "info") {

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(ModalComponent);

    this.componentRef = componentFactory.create(this.injector);
    this.componentRef.instance.mensaje = mensaje;
    this.componentRef.instance.tipo = tipo;
    this.componentRef.instance.titulo = titulo;
    this.componentRef.instance.onCancel = () => { this.componentRef.instance.mostrarModal = false; };
    this.componentRef.instance.onSuccess = undefined;
    this.componentRef.instance.mostrarModal = true;

    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  confirm(mensaje: string, onSuccess: () => void, titulo: string = "Confirmación", tipo: string = "info",  onCancel: any = undefined) {

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(ModalComponent);

    this.componentRef = componentFactory.create(this.injector);
    this.componentRef.instance.mensaje = mensaje;
    this.componentRef.instance.tipo = tipo;
    this.componentRef.instance.titulo = titulo;
    if (onCancel)
      this.componentRef.instance.onCancel = onCancel;
    else
      this.componentRef.instance.onCancel = () => { this.componentRef.instance.mostrarModal = false; };
    this.componentRef.instance.onSuccess = onSuccess;

    this.componentRef.instance.mostrarModal = true;


    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

}
