<nav class="bs-menu-desplegable">
  <ul class="menu">
    <li *ngFor="let tab of tabs">
      <a *ngIf="!tab.urlModulo" class="link icon">
        <figure *ngIf="tab.iconoModulo">
          <img src="assets/img/svg/icon-menu-01.svg" alt="Icon 01" />
        </figure>
        <span> {{tab.nombreModulo}} </span>
      </a>
      <a *ngIf="tab.urlModulo" class="link icon"  href="{{tab.urlModulo}}" style="text-decoration: none; color: var(--bs--color-principal-negro);">
        <figure *ngIf="tab.iconoModulo">
          <img src="assets/img/svg/icon-menu-01.svg" alt="Icon 01" />
        </figure>
        <span> {{tab.nombreModulo}} </span>
      </a>
    </li>
  </ul>
</nav>
