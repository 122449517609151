<p-dialog [(visible)]="abrirModal" [closable]="false" [closeOnEscape]="false"  [draggable]="false" [resizable]="false" [modal]="true" [dismissableMask]="true"
    class="bs-modal w-1000"> 
    <ng-template pTemplate="header">
        <!-- <h2 class="bs-azul bs-center">
            <strong>Bitacora</strong>
        </h2> -->
    </ng-template>
    <ng-template pTemplate="content">
        <p class="bs-center">
        </p>
        <p-tabView>
            <p-tabPanel header="Bitacora de pin">
                <ng-template pTemplate="content">
                    <div class="bs-separador"></div>
                    <div class="col-12">
                        <div class="bs-tabla-datos">
                            <p-table [value]="listaBitacora"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} datos"
                                [paginator]="true" [rows]="4" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th rowspan="2" width="20%" pSortableColumn="etiqueta" scope="col">
                                            <span>Usuario</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="20%" pSortableColumn="cantidad" scope="col">
                                            <span>Rut</span>
                                            <p-sortIcon field="cantidad"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="15%" pSortableColumn="etiqueta" scope="col">
                                            <span>Fecha registro</span>
                                        <th rowspan="2" width="45%" pSortableColumn="etiqueta" scope="col">
                                            <span>Detalle</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-i="index">
                                    <tr>
                                        <td>{{ data.usuario }}</td>
                                        <td>{{ data.rut | formatRut }}</td>
                                        <td>{{ data.fecha | dateFormat}}</td>
                                        <td><div [innerHTML]="data.detalle"></div></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>

        <div class="bs-separador h-10"></div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="simple">
            <button type="button" class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-secondary" (click)="cerrarModal()">Cerrar</button>
        </div>
    </ng-template>
</p-dialog>