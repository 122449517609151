import { Injectable } from '@angular/core';
import { SesionModel } from '../model/sesion.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SesionService {
  private nombreSesion: string = 'sesionPines';

  constructor(private storageService: StorageService) {}

  GuardarSesion(data: SesionModel): void {
    this.storageService.Set(this.nombreSesion, data);
  }

  ObtenerSesion(): SesionModel {
    return this.storageService.Get(this.nombreSesion) as SesionModel;
  }

  BorrarSesion(): void {
    this.storageService.Delete(this.nombreSesion);
  }

  EstaLogueado(): boolean {
    let sesion = this.storageService.Get(this.nombreSesion) as SesionModel;
    return sesion != null && sesion != undefined;
  }
}
