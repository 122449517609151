import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SolicitarPinesServiceService } from 'src/app/services/solicitar-pines-service.service';
import { Pine } from 'src/app/model/pines';
import { ComplementosServiceService } from 'src/app/services/complementos-service.service';
import { PinesServiceService } from 'src/app/services/pines-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { SesionService } from 'src/app/services/sesion.service';
import { ConvenioService } from 'src/app/services/convenio.service';

@Component({
  selector: 'app-crear-pines-general',
  templateUrl: './crear-pines-general.component.html',
  styleUrls: ['./crear-pines-general.component.css']
})
export class CrearPinesGeneralComponent implements OnInit {

  registerForm!: FormGroup;
  submitted = false;
  public error_carga_inicial: boolean = false;
  public loader_html: boolean = false;
  public modal_mostrar: boolean = false;
  public modal_msj_principal: string = 'mensaje principal';
  public modal_msj_secundario: string = 'mensaje secundario';
  public modal_boton_nombre: string = 'nombre btn';
  guardarPine: boolean = false;
  public dateNow = new Date();
  public idPineDesactivar: string = '0';


  /* sitioWeb */
  public datos_sitio_web: any[] = [];
  public tipo_sitio_web: string = '';
  /* usuarioComercial */
  public usuarios_comerciales: any[] = [];
  public usuarioComercial: any;
  public datos: any[] = [];
  public tipo_datos: string = '';
  /* rutUsuarioComercial*/
  /* descuento */
  /* cantidad */
  /* vigenciaDesde */
  public vigenciaDesde: Date = new Date();
  /* vigenciaHasta */
  public vigenciaHasta: Date = new Date();

  /* derivaAsigna */
  public datos_Deriva: any[] = [];
  public tipo_deriva: any;

  /* datos grilla */
  public cantidadPines: number = 1000;
  public registrosInicio: number = 0;
  public registroFin: number = 0;
  public paginacionActual: number = 1;
  public nroPaginas: number = 0;
  public listPinesGrid: any = {};
  public listPaginasGrid: any = {};
  public elementos_tabla: any[] = [];
  public cantidad?: number;

  public idDescuentoGenerico = environment.ID_DESCUENTO_GENERICO;

  public nroPin = '';

  public convenios: any[] = [];

  public convenio: any;

  //#region "LifeCycle"
  constructor(
    private formBuilder: FormBuilder,
    private serviceSolicitarPines: SolicitarPinesServiceService,
    private complementosService: ComplementosServiceService,
    private loaderService: LoaderService,
    private sesionService: SesionService,
    private convenioService: ConvenioService

  ) {
    this.dateNow.setDate(this.dateNow.getDate() - 1);
    this.registerForm = this.initValidatorsForm();
    this.registerForm.get('rutUsuarioComercial')?.disable({ onlySelf: true });
  }

  async ngOnInit() {
    try {

      await this.procesoCargaInicial();

    } catch (error) {
    }
  }

  async procesoCargaInicial() {
    this.loader_html = true;
    this.loaderService.show(0, "Cargando datos...");

    this.configuracionGridPines();
    this.loaderService.show(0, "Obteniendo configuracion de pines...");

    this.obtenerSitiosWebs();
    this.loaderService.show(0, "Obteniendo sitios web...");

    this.obtenerUsuariosComerciales();
    this.loaderService.show(0, "Obteniendo usuarios comerciales...");

    this.obtenerAcciones();
    await this.obtenerConvenios();
    this.loaderService.close();

  }
  //#endregion "LifeCycle"

  //#region "Events"
  onChangeUsuarioComercial() {
    let pine = this.registerForm.value;

    if (pine.usuarioComercial != "") {
      for (let item of this.usuarios_comerciales) {
        if (item.idUsuario == pine.usuarioComercial.id) {
          this.registerForm.patchValue({
            'rutUsuarioComercial': item.rut + '-' + item.dv
          });
        }
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid || (!this.nroPin && (this.tipo_deriva?.id == this.idDescuentoGenerico))) {
      return;
    }

    if (this.tipo_deriva.id == this.idDescuentoGenerico && !this.convenio) {
      return;
    }

    this.guardarPine = true;
    this.error_carga_inicial = false;
    this.mostrar_modal('Creación de Pine', '¿Esta seguro de guardar nuevo pine?', 'Guardar');

  }
  //#endregion "Events"

  //#region "Metods"
  get controlsFormErros() {
    return this.registerForm.controls;
  }

  initFormPin() {
    this.submitted = false;
    this.registerForm.reset();
    this.vigenciaDesde = new Date();
    this.vigenciaHasta = new Date();
    this.idPineDesactivar = '0';

    this.error_carga_inicial = false;
    this.loader_html = false;
    this.visualizacionModal(false);
  }

  initValidatorsForm(): FormGroup {
    return this.formBuilder.group({
      //usuarioComercial: ['', [Validators.required, Validators.pattern(/^[A-Za-z\s\xF1\xD1]+$/)]],
      usuarioComercial: ['', Validators.required],
      rutUsuarioComercial: ['', Validators.required],
      descuento: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.min(0), Validators.max(90)]],
      cantidad: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), , Validators.min(0)]],
      vigenciaDesde: ['', [Validators.required, this.fechaDesdeHoyValidator()]],
      vigenciaHasta: ['', [Validators.required, this.fechaHastaMinimaValidator()]],
      sitioWeb: ['', Validators.required],
      derivaAsigna: ['', Validators.required],
      numeroPin: [''],
      convenio: ['']
    })
  }

  fechaDesdeHoyValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      let validaFechaDesde = control.value > this.dateNow ? true : false;
      if (validaFechaDesde)
        return {}
      else
        return {
          fechaDesdeHoyValidator: !validaFechaDesde,
          fecha: control.value,
          dateNow: this.dateNow
        };
    };
  };

  fechaHastaMinimaValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      let fechaDesde = this.registerForm?.value?.vigenciaDesde;
      if (typeof fechaDesde === 'object' && fechaDesde !== null) {

        let validaFechaHasta = control.value >= fechaDesde ? true : false;
        if (validaFechaHasta)
          return {}
        else
          return {
            fechaHastaMinimaValidator: !validaFechaHasta
          };
      }
      return {
        fechaHastaMinimaValidator: false
      };
    };
  };

  construir_pine() {
    let nvoPine = this.registerForm.value;
    const dt = new Date().toLocaleString("en-US", { timeZone: "America/Santiago" });
    let UsuarioLogueado = this.sesionService.ObtenerSesion();
    return new Pine(
      UsuarioLogueado.idUsuario,                                  //  idUsuarioCreador: number,
      new Date(dt),                       //  public fechaSolicitud: Date,
      nvoPine.cantidad,                   //  public cantidad: number,
      nvoPine.descuento,                  //  public porcentajeDescuento: number,
      1,                                  //  public estado: number,
      nvoPine.usuarioComercial.id,        //  public idUsuarioComercial: number,
      nvoPine.sitioWeb.id,                //  public idSistema: number,
      new Date(nvoPine.vigenciaDesde),    //  public fechaCaducidadDesde: Date,
      new Date(nvoPine.vigenciaHasta),    //  public fechaCaducidadHasta: Date,
      nvoPine.derivaAsigna.id,            //  public idAccion: number,
      1,                                  //  public idEmpresa: number,
      this.nroPin,
      this.convenio?.idInterno
    );
  }

  generarListaPines() {
    this.elementos_tabla = [];
    for (let elem of this.listPinesGrid) {
      let pineItem = {
        id: elem.idSolicitudCreacionPines,
        codigo: elem.idSolicitudCreacionPines,
        cantidad: elem.cantidad,
        cantAsingEjecutivo: elem.cantidadAsignadaAEjecutivo,
        porcentaje: elem.porcentajeDescuento,
        fechaCreacion: this.formatoFecha(new Date(elem.fechaCreacion)),
        estado: elem.estado == 1 ? 'Activo' : elem.estado == 0 ? 'Inactivo' : '',
        creadorRut: elem.usuarioCreador.rut,
        creadorNombre: elem.usuarioCreador.nombre,
        asignadoRut: elem.usuarioComercial.rut,
        asignadoNombre: elem.usuarioComercial.nombre,
        sitioWeb: elem.sistema.nombre,
        fechaDesde: this.formatoFecha(new Date(elem.fechaCaducidadDesde)),
        fechaHasta: this.formatoFecha(new Date(elem.fechaCaducidadHasta)),
        accion: elem.accion.nombre,
      };

      this.elementos_tabla.push(pineItem);
    }
  }

  async aceptar_modal() {
    this.visualizacionModal(false);

    if (this.guardarPine) {
      this.guardarPine = false;
      this.loader_html = true;
      const pine = this.construir_pine();
      await this.crearPine(pine);
      this.configuracionGridPines();
      this.loader_html = false;
      // this.mostrar_modal('Creación de Pine', 'Creación de Pine Exitoso.', 'Aceptar');
    } else if (this.idPineDesactivar !== '0') {
      await this.desactivarPine(this.idPineDesactivar);
    }
  }

  desactivar_pin(idPin: string) {
    this.idPineDesactivar = idPin;
    this.error_carga_inicial = false;
    this.mostrar_modal('Desactivar Pine', '¿Esta seguro de desactivar el pine seleccionado?', 'Desactivar');
  }

  mostrar_modal(msjPrincipal: string, msjSecundario: string, nombreBoton: string) {
    this.modal_msj_principal = msjPrincipal;
    this.modal_msj_secundario = msjSecundario;
    this.modal_boton_nombre = nombreBoton;

    this.visualizacionModal(true);
  }

  formatoFecha(fecha: Date) {
    const dia = ("0" + fecha.getDate()).slice(-2);
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    return (dia + '-' + mes + '-' + fecha.getFullYear());
  }

  async procesarDataRequestConfiguracion(response: any) {

    if (response.solicitudPines.registrosTotales > this.cantidadPines) {

      this.cantidadPines = response.solicitudPines.registrosTotales;
      this.configuracionGridPines();

    } else {

      for (let item of response.solicitudPines.lstPaginas) {
        this.registrosInicio = item.primerRegistro;
        this.registroFin = item.ultimoRegistro;
      }

    }

    this.obtenerPines(this.registrosInicio.toString(), this.registroFin.toString());
    this.loader_html = false;
    this.error_carga_inicial = false;
  }
  //#endregion "Metods"

  //#region "HttpClient"

  async crearPine(pine: Pine) {
    try {
      this.error_carga_inicial = true;
      let response = await this.serviceSolicitarPines.createPine(pine);
      this.initFormPin()
      this.error_carga_inicial = false;
      this.mostrar_modal('Creación de Pine', 'Creación de Pine Exitoso.', 'Aceptar');
    } catch (error: any) {
      if (error.ok == false) {
        this.mostrar_modal('Creación de Pine', 'Error al intentar crear registro', 'Aceptar');
      }

    }
  }

  async desactivarPine(pin: string) {
    try {
      this.error_carga_inicial = true;
      let response = await this.serviceSolicitarPines.desactivarPin(pin);
      this.initFormPin()
      this.error_carga_inicial = false;
      let datosGrilla = this.elementos_tabla;
      this.elementos_tabla = [];
      for (const [key, value] of Object.entries(datosGrilla)) {
        if (pin == value.id) {
          value.estado = 'Inactivo';
        }
        this.elementos_tabla.push(value)
      }

      this.mostrar_modal('Desactivar Pin', 'Desactivación de Pin Exitoso.', 'Aceptar');
    } catch (error: any) {
      this.loader_html = false;
      if (error.ok == false) {
        // this.mostrar_modal('Creación de Pine', 'Error al intentar crear registro. Favor de comunicarse con...', 'Aceptar');
      }

    }
  }

  configuracionGridPines() {
    this.error_carga_inicial = true;
    this.serviceSolicitarPines.getListaPines(this.cantidadPines).subscribe({

      next: (response) => {
        if (response.estado == true) {

          this.procesarDataRequestConfiguracion(response);

        } else {
          this.loader_html = false;
          this.error_carga_inicial = true;
          this.mostrar_modal('Creación de Pine', `Al intentar obtener pines se ocasionó el siguiente error: "${response.mensaje}" Favor de comunicarse con soporte.`, 'Aceptar');
        }
      },

      error: (error) => {
        this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con soporte.', 'Aceptar');
        }
      }
    });
  }

  obtenerAcciones() {
    this.error_carga_inicial = true;
    this.complementosService.getAcciones().subscribe({
      next: (response) => {
        for (let item of response!.acciones) {
          let sistema = {
            id: item.idAccion,
            nombre: item.nombre
          }
          this.datos_Deriva.push(sistema);
          this.error_carga_inicial = false;
        }

      },
      error: (error) => {
        this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con... 1', 'Aceptar');
        }
      }
    })
  }

  obtenerPines(idInicio: string, idFin: string) {

    this.serviceSolicitarPines.getRegistroSolicitudesPaginadas(idInicio, idFin).subscribe({
      next: (response) => {
        this.listPinesGrid = response.solicitudPines;
        this.generarListaPines();
      },
      error: (error) => {
        this.loader_html = false;
      }
    })
  }

  obtenerSitiosWebs() {
    this.error_carga_inicial = true;
    this.complementosService.getSistemas().subscribe({
      next: (response) => {
        for (let item of response.sistemas) {
          let sitioWeb = {
            id: item.idSistema,
            nombre: item.nombre
          }
          this.datos_sitio_web.push(sitioWeb);
          this.error_carga_inicial = false;
        }
      },
      error: (error) => {
        this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con... 2', 'Aceptar');
        }
      }
    })
  }

  obtenerUsuariosComerciales() {
    this.error_carga_inicial = true;
    this.complementosService.getUsuariosComerciles().subscribe({
      next: (response) => {
        for (let item of response!.usuarios) {
          let usuarioComercial = {
            id: item.idUsuario,
            nombre: item.nombre
          }
          this.datos.push(usuarioComercial);
          this.usuarios_comerciales.push(item);
          this.error_carga_inicial = false;
        }
      },
      error: (error) => {
        this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con... 3', 'Aceptar');
        }
      }
    })
  }
  //#endregion "HttpClient"

  //#region ""
  visualizacionModal(estado: boolean) {
    this.modal_mostrar = estado;
  }

  cambiaDerivaAsigna() {
    if (this.tipo_deriva.id == this.idDescuentoGenerico) {
      this.cantidad = 1;
    }
  }

  async obtenerConvenios() {
    let result = await this.convenioService.ObtenerConvenios(1);
    this.convenios = result.convenios;
    console.log(this.convenios)
  }

}
