import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SesionModel } from 'src/app/model/sesion.model';
import { ComplementosServiceService } from 'src/app/services/complementos-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { PinesServiceService as PinesService } from 'src/app/services/pines-service.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-editar-pin',
  templateUrl: './editar-pin.component.html',
  styleUrls: ['./editar-pin.component.css']
})
export class EditarPinComponent implements OnInit {

  @Input() pinSeleccionado: any;
  @Input() abrirModal: boolean = false;
  @Output() abrirModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() alCerrarModal: EventEmitter<void> = new EventEmitter<void>();
  public sesion: SesionModel;

  public usuarios: any[];
  public usuarioSeleccionado: any;

  public submitted: boolean = false;

  constructor(private pinesServices: PinesService,
    private sesionService: SesionService,
    private complementosService: ComplementosServiceService,
    private loaderService: LoaderService,
    private modalService: ModalService
  ) { }

  async ngOnInit() {
    this.loaderService.show();
    this.sesion = this.sesionService.ObtenerSesion();
    this.pinSeleccionado.fechaCaducidadDesde = new Date(this.pinSeleccionado.fechaCaducidadDesde);
    this.pinSeleccionado.fechaCaducidadHasta = new Date(this.pinSeleccionado.fechaCaducidadHasta);

    await this.obtenerUsuarios();
    this.loaderService.close();
  }

  async obtenerUsuarios() {
    let objeto = await this.complementosService.getUsuariosComercilesPromise();
    this.usuarios = objeto.usuarios;
    this.usuarioSeleccionado = this.usuarios.find((x) => x.idUsuario == this.pinSeleccionado.idUsuario)
  }

  onChangeUsuarioComercial(usuario: any) {
    this.pinSeleccionado.idUsuario = usuario.idUsuario;
  }

  async guardarDatos() {
    this.loaderService.show();
    let respuesta = await this.pinesServices.editarPin(this.pinSeleccionado, this.sesion.idUsuario.toString());
    this.loaderService.close();

    if (respuesta.estado == true)
      this.modalService.alert("Pin modificado con éxito");

    this.abrirModalChange.emit(false);
    this.alCerrarModal.emit();
  }

  async cerrarModal(guardar: boolean) {
    this.submitted = true;
    if (guardar) {
      if (this.datosValidos()) {
        this.modalService.confirm("¿Desea modificar el pin?", async () => {
          await this.guardarDatos();
        });
      }
    } else {
      this.abrirModalChange.emit(false);
      this.alCerrarModal.emit();
    }
  }

  private datosValidos() {
    return this.pinSeleccionado.descuento && this.pinSeleccionado.fechaCaducidadDesde && this.pinSeleccionado.fechaCaducidadHasta && this.pinSeleccionado.idUsuario;
  }



}
