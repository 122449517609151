import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormatRutPipe } from 'src/app/pipes/format-rut.pipe';
import { ComplementosServiceService } from 'src/app/services/complementos-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { PinesServiceService } from 'src/app/services/pines-service.service';
import { SolicitarPinesServiceService } from 'src/app/services/solicitar-pines-service.service';

@Component({
  selector: 'app-reporte-pines',
  templateUrl: './reporte-pines.component.html',
  styleUrls: ['./reporte-pines.component.css']
})
export class ReportePinesComponent implements OnInit {
  registerForm!: FormGroup;
  submitted = false;
  public error_carga_inicial: boolean = false;
  public loader_html: boolean = false;

  public modal_mostrar: boolean = false;
  public modal_msj_titular: string = 'mensaje principal';
  public modal_msj_texto: string = 'mensaje secundario';
  public modal_boton_nombre: string = 'nombre btn';
  public datos_sitio_web: any[] = [];
  public tipo_sitio_web: string = '';
  public datos_Deriva: any[] = [];
  public tipo_deriva: any;


  /* Fecha Asig Inicial */
  /* Fecha Asig Final */
  /* Nombre usuario Asignado */
  public datos: any[] = [];
  public tipo_datos: string = '';
  public usuarios_comerciales: any[] = [];
  /* Rut */

  /* datos grilla */
  public elementos_tabla: any[] = []
  public listReporte: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private servicePines: PinesServiceService,
    private complementosService: ComplementosServiceService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private rutPipe: FormatRutPipe) { }

  ngOnInit() {
    this.loader_html = true;
    this.registerForm = this.initForm();
    this.obtenerUsuariosComerciales();
    this.obtenerAcciones();
    this.obtenerSitiosWebs();
    this.registerForm.get('rutUsuarioComercial')?.disable();
  }

  async onSubmitReporte() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    let nvoReporte = this.registerForm.value;
    if (nvoReporte.fechaInicial > nvoReporte.fechaFinal) {
      this.error_carga_inicial = true;
      this.mostrar_modal('Reporte', 'Fecha inicial es superior a fecha final', 'Aceptar');
      return;
    }

    this.loader_html = true;
    await this.buscarReporte();
    //this.loader_html = false;
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }



  initForm(): FormGroup {
    return this.formBuilder.group({
      fechaInicial: ['', [Validators.required]],
      fechaFinal: ['', Validators.required],
      nombreUsuario: ['', []],
      rut: ['', []],
      sitioWeb: [''],
      derivaAsigna: ['']
    })
  }

  get f() {
    return this.registerForm.controls;
  }

  async buscarReporte() {
    try {
      this.error_carga_inicial = true;
      let nvoReporte = this.registerForm.value;
      let usuarioId = nvoReporte.nombreUsuario?.id > 0 ? nvoReporte.nombreUsuario?.id : undefined;

      // if (usuarioId == null)
      //   this.obtenerReportePorFecha(nvoReporte.fechaInicial, nvoReporte.fechaFinal);
      // else
      await this.obtenerReportePorUsuario(usuarioId, nvoReporte.fechaInicial, nvoReporte.fechaFinal, nvoReporte.derivaAsigna?.id, nvoReporte.sitioWeb?.id);
      // this.initFormPin()

      this.error_carga_inicial = false;


      //this.mostrar_modal('Creación de Pine', 'Creación de Pine Exitoso.', 'Aceptar');
    } catch (error: any) {
      if (error.ok == false) {
        //this.mostrar_modal('Creación de Pine', 'Error al intentar crear registro. Favor de comunicarse con...', 'Aceptar');
      }

    }
  }

  obtenerReportePorFecha(fechaDesde: Date, fechaFin: Date) {
    this.servicePines.getPinesPorRangoFecha(
      this.formatoFechammddyyyy(fechaDesde),
      this.formatoFechammddyyyy(fechaFin))
      .subscribe({
        next: (response: any) => {
          this.listReporte = response.pines;
          this.generarListaReporte();
          this.loader_html = false;
        },
        error: (error: any) => {
          this.loader_html = false;
          console.log(error)
        }
      })
  }

  async obtenerReportePorUsuario(idUsuario: number, fechaDesde: Date, fechaFin: Date, idaccion: number, idSitioWeb: number) {
    this.loaderService.show(0, "Obteniendo pines...")
    let response = await this.servicePines.getPinesPorRangoFechaUsuario(
      idUsuario,
      this.formatoFechammddyyyy(fechaDesde),
      this.formatoFechammddyyyy(fechaFin),
      idaccion,
      idSitioWeb
    );

    if (response.estado == true) {
      this.listReporte = response.pines;
      this.generarListaReporte();
      this.loaderService.close();
    } else {
      this.loaderService.close();
      this.modalService.alert("No se pudo realizar la operación", "Error", "error");

    }


  }

  obtenerUsuariosComerciales() {
    this.error_carga_inicial = true;
    this.datos.push({id: 0,nombre:'-Todos-'});

    this.complementosService.getUsuariosComerciles().subscribe({
      next: (response: any) => {
        for (let item of response!.usuarios) {
          let usuarioComercial = {
            id: item.idUsuario,
            nombre: item.nombre
          }
          this.datos.push(usuarioComercial);
          this.usuarios_comerciales.push(item);
          this.error_carga_inicial = false;
          this.loader_html = false;
        }
      },
      error: (error: any) => {
        this.loader_html = false;
        if (error.ok == false) {
          this.mostrar_modal('Creación de Pine', 'Error al obtener información inicial. Favor de comunicarse con... 3', 'Aceptar');
        }
      }
    })
  }

  //#region "Metods"

  aceptar_modal() {
    this.modal_mostrar = false;
  }

  mostrar_modal(msjPrincipal: string, msjSecundario: string, nombreBoton: string) {
    this.modal_msj_titular = msjPrincipal;
    this.modal_msj_texto = msjSecundario;
    this.modal_boton_nombre = nombreBoton;

    this.modal_mostrar = true;
  }

  formatoFecha(fecha: Date) {
    const dia = ("0" + fecha.getDate()).slice(-2);
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    return (dia + '-' + mes + '-' + fecha.getFullYear());
  }

  formatoFechammddyyyy(fecha: Date) {
    const dia = ("0" + fecha.getDate()).slice(-2);
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    return (mes + '-' + dia + '-' + fecha.getFullYear());
  }

  generarListaReporte() {
    this.elementos_tabla = [];
    for (let elem of this.listReporte) {
      let pineItem = {
        //enum: elem.nroPin,  //
        pin: elem.nroPin,
        estado: elem.estado == 1 ? 'Activo' : elem.estado == 0 ? 'Inactivo' : '',
        cantidad: elem.descuento, //
        dscto: elem.descuento,
        dsctoUtilizado: elem.descuentoUtilizado,
        usuarioOrigen: elem.usuarioOrigen,
        usuarioDestino: elem.usuario,//
        observacion: elem.observacion,
        nroCotizacion: elem.nroCotizacion,
        nroPoliza: elem.nroPoliza,
        fechaAsignacion: this.formatoFecha(new Date(elem.fecha_Asignacion)),
        fechaUtilizacion: this.formatoFecha(new Date(elem.fechaUtilizacion)),
        nombreConvenio: elem.nombreComvenio,
      };

      this.elementos_tabla.push(pineItem);
    }
  }

  visualizacionModal(estado: boolean) {
    this.modal_mostrar = estado;
  }
  //#endregion "Metods"
  obtenerAcciones() {
    this.datos_Deriva.push({id: 0,nombre:'-Todos-'});
    this.complementosService.getAcciones().subscribe({
      next: (response2) => {
        for (let item of response2!.acciones) {
          let sistema2 = {
            id: item.idAccion,
            nombre: item.nombre
          }
          this.datos_Deriva.push(sistema2);
        }

      },
      error: (error) => {
        if (error.ok == false) {
          this.modalService.alert("Error al obtener los pines", "Error", "error")
        }
      }
    })
  }

  obtenerSitiosWebs() {
    this.datos_sitio_web.push({id: 0,nombre:'-Todos-'});
    this.complementosService.getSistemas().subscribe({
      next: (response3) => {
        for (let item of response3.sistemas) {
          let sitioWeb3 = {
            id: item.idSistema,
            nombre: item.nombre
          }
          this.datos_sitio_web.push(sitioWeb3);
        }
      },
      error: (error) => {
        if (error.ok == false) {
          this.modalService.alert("Error al obtener los datos", "Error", "error")
        }
      }
    })
  }

  onChangeUsuarioComercial() {
    let pine = this.registerForm.value;

    if (pine.nombreUsuario != "") {
      for (let item of this.usuarios_comerciales) {
        if (item.idUsuario == pine.nombreUsuario.id) {
          let rutFormateado = this.rutPipe.transform(item.rut + '-' + item.dv);
          this.registerForm.patchValue({
            'rut': rutFormateado
          });
        }
      }
    }
  }

  async descargar() {
    this.loaderService.show(0, "Descargando pines...")
    let nvoReporte = this.registerForm.value;
    let usuarioId = nvoReporte.nombreUsuario?.id > 0 ? nvoReporte.nombreUsuario?.id : null;

    try {
      let blob2 = await this.servicePines.BuscarPinesReporteriaDescargar(
        usuarioId,
        this.formatoFechammddyyyy(nvoReporte.fechaInicial),
        this.formatoFechammddyyyy(nvoReporte.fechaFinal),
        nvoReporte.derivaAsigna?.id,
        nvoReporte.sitioWeb?.id
      );
      const url = URL.createObjectURL(blob2);

      // Crear un enlace temporal y hacer clic para iniciar la descarga
      const link2 = document.createElement('a');
      link2.href = url;
      link2.download = "pines.xlsx"; // Nombre de archivo sugerido
      link2.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
        this.loaderService.close();
      }, 2000);
    } catch (e) {
      this.submitted = true;
      this.loaderService.close();
    }
  }
}
