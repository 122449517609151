import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConvenioService {

  constructor(private readonly http: HttpClient) { }

  public async ObtenerConvenios(empresa: number) {
    let params = new HttpParams()
    .set('idempresa', empresa.toString())
    return this.http.get<any>(environment.API + '/v1/sistema/getConvenios',{params}).toPromise();
  }
}
