import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNGModule } from 'src/app/shared/components/prime-ng/prime-ng.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from 'src/app/components.module';
import { ReportePinesComponent } from './pages/reporte-pines/reporte-pines.component'



@NgModule({
  declarations: [ReportePinesComponent],
  imports: [
    PrimeNGModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ComponentsModule
  ]
})
export class ReportesModule { }
