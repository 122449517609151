<p-dialog [(visible)]="abrirModal" [closable]="false" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
    [modal]="true" [dismissableMask]="true" class="bs-modal w-1000">
    <ng-template pTemplate="header">
    </ng-template>
    <ng-template pTemplate="content">
        <p class="bs-center">
        </p>
        <p-tabView>
            <p-tabPanel header="Editar Descuentos">
                <br>
                <label for="form-1" class="bs-forms">
                    <label>
                        Habilitar Pines
                    </label>
                    <input [checked]="habilitaPinesBci === 1 ? true : false" [ngModel]="habilitaPinesBci" (change)="onCheckboxChange($event)" type="checkbox">
                </label>
                <br>
                <div *ngFor="let pin of pinesBci; let i = index">
                    <label for="form-1" class="bs-forms">
                        <div class="col-12 row">
                            <div class="col-md-6">
                                <input dirOnlyNumber type="text" maxlength="2"  pInputText id="form-1" value="pin"  (change)="onTextboxChange($event, i)" [ngModel]="pin"/>
                            </div>
                            <div class="col-md-6">
                                <button (click)="removePin(pin)" class="cs bs-btn btn-eliminar-bci">Eliminar</button>
                            </div>
                        </div>
                    </label>
                </div>
                <button (click)="addPin()" type="button" class="cs bs-btn bs-btn-primary" >Agregar</button>
            </p-tabPanel>
        </p-tabView>

        <div class="bs-separador h-10"></div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="simple">
            <button (click)="cerrarModal(false)" type="button" class="sn bs-btn  bs-btn-secondary" >Cancelar</button>
            <button style="visibility:hidden" type="button" class="sn bs-btn bs-btn-primary" ></button>
            <button (click)="cerrarModal(true)" type="button" class="sn bs-btn bs-btn-primary" >Guardar</button>
        </div>
    </ng-template>
</p-dialog>
