import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { LoginService } from 'src/app/services/login.service';
import { PinesServiceService as PinesService } from 'src/app/services/pines-service.service';

@Component({
  selector: 'app-lista-bitacora',
  templateUrl: './lista-bitacora.component.html',
  styleUrls: ['./lista-bitacora.component.css']
})
export class ListaBitacoraComponent implements OnInit {

  @Input() pinSeleccionado: any;
  @Input() abrirModal: boolean = false;
  @Output() abrirModalChange: EventEmitter<boolean> =    new EventEmitter<boolean>();

  public listaBitacora: any[];

  constructor(private pinesServices: PinesService, private loaderService: LoaderService
    ) { }

  async ngOnInit() {
    this.loaderService.show(0,"Cargando datos de bitacora");
    let pines = await this.pinesServices.getBitacoraPin(this.pinSeleccionado.idPin.toString());
    this.listaBitacora = pines.bitacoraPin;
    this.loaderService.close();
  }

  cerrarModal() {
    this.abrirModal = false;
    this.abrirModalChange.emit(this.abrirModal);
  }

}
