import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SesionModel } from 'src/app/model/sesion.model';
import { LoginService } from 'src/app/services/login.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-ov',
  templateUrl: './ov.component.html',
  styleUrls: ['./ov.component.css']
})
export class OvComponent implements OnInit {

  public error: boolean = false;

  constructor(private _ar: ActivatedRoute, private loginService: LoginService, private sesionService: SesionService
  ) { }

  async ngOnInit() {
    this.sesionService.BorrarSesion();
    //borrar sesion
    if (this._ar.snapshot.queryParamMap.get('rut') && this._ar.snapshot.queryParamMap.get('sessionId')) {
      let rut = this._ar.snapshot.queryParamMap.get('rut');
      let sessionId = this._ar.snapshot.queryParamMap.get('sessionId');

      let respuesta = await this.loginService.LoginOv(rut as string, sessionId as string);

      if (respuesta.exito) {
        this.sesionService.GuardarSesion(respuesta);
        window.location.href = '/';
      } else {
        this.error = true;
      }
    }
  }

}
