<div class="bs-contenedor" style="min-width: 90%;">

    <div class="bs-pestanas">
        <p-tabView>

            <p-tabPanel header="Busqueda de pines">
                <ng-template pTemplate="content">
                    <div class="bs-separador h-30"></div>

                    <form [formGroup]="registerForm" (ngSubmit)="onSubmitReporte()">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label for="form-1" class="bs-forms">
                                    <h5>Nombre usuario asignado</h5>
                                    <p-dropdown formControlName="nombreUsuario" [filter]="true" id="tipocontacto"
                                        [options]="datos" [(ngModel)]="tipo_datos"
                                        (onChange)="onChangeUsuarioComercial()" name="tipocontacto"
                                        placeholder="Seleccione usuario" optionLabel="nombre" optionValie="id"
                                        [showClear]="false" emptyMessage="Sin Datos" [autoDisplayFirst]="false"
                                        filterPlaceholder="Buscar un item " emptyFilterMessage="Sin datos"></p-dropdown>
                                </label>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <label class="bs-forms">
                                    <h5>Rut</h5>
                                    <input type="text" readonly formControlName="rut" placeholder="Ingrese rut"
                                        pInputText />
                                </label>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <label for="form-1411" class="bs-forms">
                                    <h5>Fecha asig inicial</h5>
                                    <p-calendar formControlName="fechaInicial" [firstDayOfWeek]="1" inputId="form-1411"
                                        [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                                    <div *ngIf="submitted && f.fechaInicial.errors" class="invalid-feedback">
                                        <div class="message-invalide" *ngIf="f.fechaInicial.errors.required">Campo
                                            Requerido</div>
                                    </div>
                                </label>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <label for="form-1411" class="bs-forms">
                                    <h5>Fecha asig final</h5>
                                    <p-calendar formControlName="fechaFinal" [firstDayOfWeek]="1" inputId="form-1411"
                                        [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                                    <div *ngIf="submitted && f.fechaFinal.errors" class="invalid-feedback">
                                        <div class="message-invalide" *ngIf="f.fechaFinal.errors.required">Campo
                                            Requerido</div>
                                    </div>
                                </label>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label for="form-15" class="bs-forms">
                                    <h5>Sito web</h5>
                                    <p-dropdown formControlName="sitioWeb" id="tipoSitioWeb" [options]="datos_sitio_web"
                                        [(ngModel)]="tipo_sitio_web" name="tipoSitioWeb"
                                        placeholder="Seleccione sitio web" optionLabel="nombre" optionValie="id"
                                        [showClear]="false" emptyMessage="Sin Datos"></p-dropdown>
                                    <div *ngIf="submitted && f.sitioWeb.errors" class="invalid-feedback">
                                        <div class="message-invalide" *ngIf="f.sitioWeb.errors.required">Campo requerido
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label for="form-15" class="bs-forms">
                                    <h5>Tipo pin</h5>
                                    <p-dropdown formControlName="derivaAsigna" id="tipoDeriva" [options]="datos_Deriva"
                                        [(ngModel)]="tipo_deriva" name="tipoDeriva" placeholder="Seleccione opción"
                                        optionLabel="nombre" optionValie="id" [showClear]="false"
                                        emptyMessage="Sin Datos"></p-dropdown>
                                    <div *ngIf="submitted && f.derivaAsigna.errors" class="invalid-feedback">
                                        <div class="message-invalide" *ngIf="f.derivaAsigna.errors.required">Campo
                                            Requerido
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>          
                        <div class="bs-separador h-30"></div>
             
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6">
                                <button  type="button" (click)="descargar()" class="bs-btn bs-btn-secondary">
                                    Descargar
                                </button>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6" style="text-align: right;">
                                <button class="bs-btn bs-btn-primary">
                                    Buscar 
                                </button>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6"></div>
                        </div>
                    </form>


                    <div class="bs-separador h-30"></div>
                    <div class="col-12">
                        <div class="bs-tabla-datos">
                            <p-table [value]="elementos_tabla"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} datos"
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th id="pin" rowspan="2" width="110" pSortableColumn="pin">
                                            <span>Pin</span>
                                            <p-sortIcon field="cantidad"></p-sortIcon>
                                        </th>
                                        <th id="estado" rowspan="2" width="110" pSortableColumn="etiqueta">
                                            <span>Estado</span>
                                        </th>
                                        <th id="cantidad" rowspan="2" width="110" pSortableColumn="etiqueta">
                                            <span>Cantidad</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="descto" rowspan="2" width="110" pSortableColumn="etiqueta">
                                            <span>Desto</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="desctoUtilizado" rowspan="2" width="140" pSortableColumn="etiqueta">
                                            <span>Desto Utilizado</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="usuOrigen" rowspan="2" width="140" pSortableColumn="etiqueta">
                                            <span>Usuario Origen</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="usuDestino" rowspan="2" width="140" pSortableColumn="etiqueta">
                                            <span>Usuario Destino</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="observacion" rowspan="2" width="160" pSortableColumn="etiqueta">
                                            <span>Observacion</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="nroCotizacion" rowspan="2" width="120" pSortableColumn="etiqueta">
                                            <span>Nro Cotizacion</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="nroPoliza" rowspan="2" width="120" pSortableColumn="etiqueta">
                                            <span>Nro Poliza</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="fechaAsignacion" rowspan="2" width="120" pSortableColumn="etiqueta">
                                            <span>Fecha Asignacion</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="fechaUtilizada" rowspan="2" width="120" pSortableColumn="etiqueta">
                                            <span>Fecha Utilizacion</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th id="nombreConvenio" rowspan="2" width="120" pSortableColumn="etiqueta">
                                            <span>Nombre Convenio</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-i="index">
                                    <tr>
                                        <td>{{ data.pin }}</td>
                                        <td>{{ data.estado }}</td>
                                        <td>{{ data.cantidad }}</td>
                                        <td>{{ data.dscto }}</td>
                                        <td>{{ data.dsctoUtilizado }}</td>
                                        <td>{{ data.usuarioOrigen }}</td>
                                        <td>{{ data.usuarioDestino }}</td>
                                        <td>{{ data.observacion }}</td>
                                        <td>{{ data.nroCotizacion }}</td>
                                        <td>{{ data.nroPoliza }}</td>
                                        <td>{{ data.fechaAsignacion }}</td>
                                        <td>{{ data.fechaUtilizacion }}</td>
                                        <td>{{ data.nombreConvenio }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>



                    <p-dialog [(visible)]="modal_mostrar" [draggable]="false" [resizable]="false" [modal]="true"
                        [dismissableMask]="true" class="bs-modal w-500">
                        <ng-template pTemplate="header">
                            <h2 class="bs-azul bs-center">
                                <strong>{{modal_msj_titular}}</strong>
                            </h2>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p class="bs-center">
                                <strong class="bs-negro">{{modal_msj_texto}} </strong>
                            </p>

                            <div class="bs-separador h-10"></div>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <div class="simple">
                                <button class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-primary"
                                    [ngClass]="{'bs-btn-error': error_carga_inicial, 'bs-btn-verde': !error_carga_inicial}"
                                    type="reset" (click)="aceptar_modal()">{{modal_boton_nombre}}</button>
                            </div>
                        </ng-template>
                    </p-dialog>

                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>

</div>