import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConvenioPinesService as ConvenioPinesService } from 'src/app/services/convenio-pines.service';
import { ModalService } from 'src/app/services/modal.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-editar-descuento-bci',
  templateUrl: './editar-descuento-bci.component.html',
  styleUrls: ['./editar-descuento-bci.component.css']
})
export class EditarDescuentoBciComponent implements OnInit {

  @Input() convenioSeleccionado: any;
  @Input() abrirModal: boolean = false;
  @Output() abrirModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() alCerrarModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<string>();
  public submitted: boolean = false;
  public pinesBci: any[] = [];
  public habilitaPinesBci: number;
  public checkedHabilitaPin: boolean = false;

  constructor(
    private ConvenioPinesService: ConvenioPinesService,
    private modalService: ModalService,
    private loaderService: LoaderService,

  ) { }

  ngOnInit(): void {
    this.obtenerConveniosPinesBci(this.convenioSeleccionado);
  }

  onTextboxChange(event: any, index: number): void {
    console.log(event.target.value);
    this.pinesBci[index] = event.target.value

  }

  onCheckboxChange(event: any): void {
    this.habilitaPinesBci = event.target.checked == true ? 1 : 0;
  }


  async obtenerConveniosPinesBci(convenioSeleccionadoBci: any) {
    let idConvenioBci = convenioSeleccionadoBci.idConvenio;
    let idRecursoBci = convenioSeleccionadoBci.idRecurso;
    let listado_Convenio_pinesBci = await this.ConvenioPinesService.ObtenerPinesByConvenioRecursoBci(idConvenioBci, idRecursoBci);
    this.pinesBci = listado_Convenio_pinesBci.lst_PinesConvenio.pines;
    this.habilitaPinesBci = listado_Convenio_pinesBci.lst_PinesConvenio.habilitaPin;
    console.log(listado_Convenio_pinesBci.lst_PinesConvenio);
  }

  async cerrarModal(guardar: boolean) {
    this.submitted = true;
    if (guardar) {
      if (this.validarArrayPines()) {
        this.modalService.confirm("¿Desea modificar el pin?", async () => {
          console.log("Pin descuentos para guardar");
          console.log(this.pinesBci);
          await this.guardarDatos();
        });
      }
    } else {
      this.abrirModalChange.emit(false);
      this.alCerrarModal.emit();
    }
  }




  addPin() {

    if (this.pinesBci.length < 4) {
      this.pinesBci.push('');
    }
  }


  removePin(pin: string) {

    if (this.pinesBci.length == 1) {

    } else {
      this.pinesBci = this.pinesBci.filter((p: string) => p !== pin);
    }


  }

  async guardarDatos() {
    this.loaderService.show();

    let parametros = {
      "idConvenio": this.convenioSeleccionado.idConvenio,
      "idRecurso": this.convenioSeleccionado.idRecurso,
      "habilitaPin": this.habilitaPinesBci,
      "pines": this.pinesBci
    }

    console.log("Json para guardar");
    console.log(parametros);

    let respuesta = await this.ConvenioPinesService.ActualizarPinesConvenioRecursoBci(parametros);
    this.loaderService.close();

    if (respuesta.estado == true)
      this.modalService.alert("Pines de descuentos modificados con éxito");

    this.abrirModalChange.emit(false);
    this.alCerrarModal.emit();
  }

  validarArrayPines() {

    let retorno = true;
    if (this.pinesBci.some(elemento => elemento === '')) {
      this.modalService.alert("Debe ingresar pin de descuento en la(s) caja(s) de texto agregada(s).");
      retorno = false;
    }
    return retorno;
  }

}
