import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {

  @Input() mensaje: string;
  @Input() titulo: string;
  @Input() onCancel: () => void;
  @Input() onSuccess?: () => void;
  @Input() mostrarModal: boolean = false;
  @Input() tipo: string = "info";

  public exito() {
    this.mostrarModal = false;

    if (this.onSuccess)
      this.onSuccess();
  }
}
