import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  public mensaje: string = "";
  public codigo: string = "";

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params : any) => {

      this.codigo = params.get('id') as string;

      switch(this.codigo ){
           case "401": this.mensaje = "No tiene acceso a esta aplicación"; break;
           case "500": this.mensaje = "Error interno de la aplicación"; break;
           case "404": this.mensaje = "No tiene acceso a esta aplicación"; break;
      } 

    });
  }

}
