export const clean = (rut: any) => {

  if(rut.toString().indexOf('-') != -1 || rut.toString().indexOf(' ') != -1){
    rut = rut.replace(new RegExp('-', 'g'), '');
    rut = rut.replace(new RegExp(' ', 'g'), '');
  }else{
    rut = rut + calcularDv(rut.toString())
  }

  rut = rut.toString().substr(0,rut.length-1)+"-"+rut.toString().substr(rut.length-1, rut.length);
  console.log(rut)

  let reg = "/^0+|[^0-9kK]+";

  return typeof rut === 'string'
    ? rut.replace(new RegExp(reg, 'g'), '').toUpperCase() //rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
    : '';
};

export const validate = (rut: string) => {
  if (typeof rut !== 'string') {
    return false;
  }

  // if it starts with 0 we return false
  // so a rut like 00000000-0 will not pass
  if (/^0+/.test(rut)) {
    return false;
  }

  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return false;
  }

  rut = clean(rut);

  let t = parseInt(rut.slice(0, -1), 10);
  let m = 0;
  let s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }

  const v = s > 0 ? '' + (s - 1) : 'K';
  return v === rut.slice(-1);
};

export const format = (
  rut: string,
  options = {
    dots: true,
  }
) => {
  rut = clean(rut);

  let result;
  if (options.dots) {
    result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1);
    for (let i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + '.' + result;
    }
  } else {
    result = rut.slice(0, -1) + '-' + rut.substr(rut.length - 1);
  }

  return result;
};

export const calcularDv = (rut: any) =>{
  rut = rut.toString();
  // Eliminar puntos y guiones y convertir a mayúsculas
  rut = rut.replace(/\./g, '').replace(/-/g, '').toUpperCase();

  // Obtener el número base
  let numBase = parseInt(rut);

  // Calcular el dígito verificador
  let suma = 0;
  let multiplicador = 2;

  while (numBase > 0) {
    suma += (numBase % 10) * multiplicador;
    numBase = Math.floor(numBase / 10);
    multiplicador = multiplicador < 7 ? multiplicador + 1 : 2;
  }

  let resultado = 11 - (suma % 11);
  resultado = resultado === 11 ? 0 : resultado;

  return resultado.toString();
}
