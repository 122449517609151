import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DateFormatPipe } from '../pipes/fotmat-date.pipe';

@Injectable({
  providedIn: 'root'
})
export class PinesServiceService {

  constructor(private readonly http: HttpClient, private fechaPipe: DateFormatPipe) { }


  async desactivarPin(idPin: string) {
    return this.http.put<any>(
      environment.API + `/v1/Pines/DesactivarPin?NroPin=${idPin}&IdSistema=1`,
      {
        params: {
          NroPin: idPin,
          IdSistema: 1
        }
      }).toPromise();
  }

  async asignarPine(dscto: number, idUsuCoti: number, idBolsaDist: number,cantidad: number, rut: number, dvRut: string, convenioId: number|undefined = undefined) {
    const body = {
      "descuento": dscto,
      "idUsuarioCotizador": idUsuCoti,
      "idBolsaDistribucionPines": idBolsaDist,
      "idConvenio" : convenioId,
      "cantidadPines" : cantidad
      //"rut": rut,
      //"dvRut": dvRut
    };
    return this.http.post<any>(
      environment.API + '/v1/Pines/CrearPin', body).toPromise();
  }

  getPinesPorUsuario(id: any) {
    return this.http.get<any>(
      environment.API + '/v1/Pines/BuscarPinesPorUsuario',
      {
        params: {
          IdUsuario: id,
        }
      })
  }

  getPinesPorRangoFecha(fechaInicio: any, fechaFin: any) {
    return this.http.get<any>(
      environment.API + '/v1/Pines/BuscarPinesPorFechaOPropietario',
      {
        params: {
          FechaInicio: fechaInicio,
          FechaFin: fechaFin
        }
      })
  }

  async getPinesPorRangoFechaUsuario(id: any, fechaInicio: any, fechaFin: any, idaccion: number, idSitioWeb: number) {
    let params = new HttpParams()
    .set('IdUsuario', id && id != 0 ? id?.toString(): '')
    .set('FechaInicio', fechaInicio.toString())
    .set('FechaFin', fechaFin.toString())
    .set('IdAccion', idaccion && idaccion!=0 ? idaccion?.toString(): '')
    .set('IdSistema', idSitioWeb && idSitioWeb != 0 ? idSitioWeb?.toString(): '')

    return this.http.get<any>(environment.API + '/v1/Pines/BuscarPinesReporteria',{params}).toPromise();
  }

  async getPinesAsignadosCliente(idUsuario:string) {
    let params = new HttpParams()
    .set('idUsuario', idUsuario.toString())
    return this.http.get<any>(environment.API + '/v1/pines/BuscarPinesGenericosPorUsuario',{params}).toPromise();
  }

  async getBitacoraPin(idPin:string) {
    let params = new HttpParams()
    .set('idPin', idPin.toString())
    return this.http.get<any>(environment.API + '/v1/sistema/GetBitacoraPin',{params}).toPromise();
  }

  async editarPin(pin: any, idUsuario: string) {
    let params = new HttpParams()
    .set('IdUsuario', idUsuario.toString())
    .set('FechaInicio', this.fechaPipe.transform(pin.fechaCaducidadDesde, 'yyyy-MM-dd'))
    .set('FechaFin', this.fechaPipe.transform(pin.fechaCaducidadHasta, 'yyyy-MM-dd'))
    .set('IdEjecutivo', pin.idUsuario.toString())
    .set('DescuentoPin', pin.descuento.toString())
    .set('IdPin', pin.idPin.toString())

    return this.http.get<any>(environment.API + '/v1/pines/EditarPinGenerico',{params}).toPromise();
  }

  async getPinesAsignadosConvenio(idUsuario:string) {
    let params = new HttpParams()
    .set('idUsuario', idUsuario.toString())
    return this.http.get<any>(environment.API + '/v1/pines/BuscarPinesConvenioPorUsuario',{params}).toPromise();
  }

  async cambiarEstadoPinPorId(idPin:string, desactivar: boolean) {
    return this.http.put<any>(environment.API + '/v1/pines/DesactivarPinPorId?IdPin='+idPin,null).toPromise();
  }

  public async descargarPines(idBolsa: string): Promise<any> {    
    return this.http.get(environment.API + '/v1/pines/DescargarArchivoConPinesConvenios?idBolsa='+idBolsa,  { responseType: 'blob' }).toPromise();
  }

  async BuscarPinesReporteriaDescargar(id: any, fechaInicio: any, fechaFin: any, idaccion: number, idSitioWeb: number) {

    let params = "IdUsuario="+(id && id != 0 ? id :'')+
    "&FechaInicio="+this.fechaPipe.transform(fechaInicio.toString(), 'yyyy-MM-dd')+
    "&FechaFin="+this.fechaPipe.transform(fechaFin.toString(), 'yyyy-MM-dd')+
    "&IdAccion="+(idaccion && idaccion!=0  ? idaccion.toString(): '')+
    "&IdSitioWeb="+(idSitioWeb && idSitioWeb !=0 ? idSitioWeb : '');

    return this.http.get(environment.API + '/v1/Pines/BuscarPinesReporteriaDescargar?'+params,  { responseType: 'blob' }).toPromise();
  }

}
