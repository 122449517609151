import { Component, OnInit } from '@angular/core';
import { SesionModel } from 'src/app/model/sesion.model';
import { ComplementosServiceService } from 'src/app/services/complementos-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PinesServiceService } from 'src/app/services/pines-service.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-pines-asignados-convenio',
  templateUrl: './pines-asignados-convenio.component.html',
  styleUrls: ['./pines-asignados-convenio.component.css']
})
export class PinesAsignadosConvenioComponent implements OnInit {
  public sesion: SesionModel;
  public elementos_tabla_asignados: any[];

  constructor(
    private servicePines: PinesServiceService,
    private sesionService: SesionService,
    private loaderService: LoaderService
  ) {
  }
  async ngOnInit() {
    this.loaderService.show(0, "Cargando datos de pines...");
    this.sesion = this.sesionService.ObtenerSesion();
    await this.obtenerPines();
    this.loaderService.close();
  }

  async obtenerPines() {
    let pines = await this.servicePines.getPinesAsignadosConvenio(this.sesion.idUsuario.toString());
    this.elementos_tabla_asignados = pines.lst_Pines;
  }

  async descargar(pin: any) {
    this.loaderService.show(0, "Descargando pines...")

    try {
      let blob = await this.servicePines.descargarPines(pin.idBolsaDistribucion.toString());
      const url = URL.createObjectURL(blob);

      // Crear un enlace temporal y hacer clic para iniciar la descarga
      const link = document.createElement('a');
      link.href = url;
      link.download = "pines bolsa "+pin.idBolsaDistribucion+".xlsx"; // Nombre de archivo sugerido
      link.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
        this.loaderService.close();
      }, 2000);
    } catch (e) {
      this.loaderService.close();
    }
  }
}
