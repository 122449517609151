import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNGModule } from 'src/app/shared/components/prime-ng/prime-ng.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormatRutPipe } from 'src/app/pipes/format-rut.pipe';
import { ComponentsModule } from 'src/app/components.module';
import { CrearPinesGeneralComponent } from './pages/crear-pines-general/crear-pines-general.component';

@NgModule({
  declarations: [
    CrearPinesGeneralComponent,
  ],
  imports: [
    PrimeNGModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ComponentsModule
  ],
  exports: [
    FormatRutPipe
  ],
})
export class CreacionPinesModule { }
