import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ComplementosServiceService {

  constructor(private readonly http: HttpClient) { }

  getSistemas() {
    return this.http.get<any>(
      environment.API + '/v1/Sistema/GetSistema')
  }

  getAcciones() {
    return this.http.get<any>(
      environment.API + '/v1/Sistema/GetAcciones')
  }

  getUsuariosComerciles() {
    return this.http.get<any>(
      environment.API + '/v1/Usuarios/GetUsuariosComerciales')
  }

  public async getUsuariosComercilesPromise() {
    return this.http.get<any>(
      environment.API + '/v1/Usuarios/GetUsuariosComerciales').toPromise()
  }

}
