import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BolsaPinesServiceService } from 'src/app/services/bolsa-pines-service.service';
import { ComplementosServiceService } from 'src/app/services/complementos-service.service';
import { ConvenioService } from 'src/app/services/convenio.service';
import { PinesServiceService } from 'src/app/services/pines-service.service';
import { SesionService } from 'src/app/services/sesion.service';
@Component({
  selector: 'app-pines-asignados-cotizadores',
  templateUrl: './pines-asignados-cotizadores.component.html',
  styleUrls: ['./pines-asignados-cotizadores.component.css']
})
export class PinesAsignadosCotizadoresComponent implements OnInit {

  public elementos_tabla_asignados: any[] = [];
  registerForm!: FormGroup;
  public modal_mostrar: boolean = false;
  public modal_msj_principal: string = 'mensaje principal';
  public modal_msj_secundario: string = 'mensaje secundario';
  public modal_boton_nombre: string = 'nombre btn';
  public error_carga_inicial: boolean = false;
  public modal_derivar: boolean = false;
  public tipo_accion: string = '';
  public tipo_datos: string = '';
  public datos: any[] = [];
  submitted = false;
  public tipo_cantidad: any;
  public datos_cantidad: any[] = [];
  public modal_derivar_convenio: boolean = false;
  public listTipoAccion: any[] = [];
  public listPinesAsignarGrid: any = {};
  public usuarios_comerciales: any[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private complementosService: ComplementosServiceService,
    private servicePines: PinesServiceService,
    private sesionService: SesionService
  ) {
    this.registerForm = this.initValidatorsForm();
  }

  ngOnInit() {
    try {
      // this.loader_html = true;
      this.obtenerUsuariosComerciales();
      this.configuracionGridPinesAsignar();
    } catch (error) {
      console.log('ngOnInit');
      console.log(error)
    }
  }

  initValidatorsForm(): FormGroup {
    return this.formBuilder.group({
      usuarioComercial: ['', Validators.required],
      cantidad: ['', Validators.required],
    })
  }

  obtenerUsuariosComerciales() {
    this.error_carga_inicial = true;
    this.complementosService.getUsuariosComerciles().subscribe({
      next: (response) => {
        for (let item of response!.usuarios) {
          let usuarioComercial = {
            id: item.idUsuario,
            nombre: item.nombre
          }
          this.datos.push(usuarioComercial);
          this.usuarios_comerciales.push(item);
          this.error_carga_inicial = false;
        }
      }
    })
  }

  mostrar_modal_asignar(idSolicitud: number) {
    this.registerForm.reset();


    for(let x=0; this.elementos_tabla_asignados.length > x; x++){
      
      if(this.elementos_tabla_asignados[x].idSolicitudCreacionPines == idSolicitud){
        this.elementos_tabla_asignados[x].mostrarPin = true;
      }
    }
    
  }

  async aceptar_modal() {
    this.modal_mostrar = false;

  }

  get controlsFormErros() {
    return this.registerForm.controls;
  }

  configuracionGridPinesAsignar() {
    this.error_carga_inicial = true;
    let UsuarioLogueado=this.sesionService.ObtenerSesion();

    this.servicePines.getPinesPorUsuario(UsuarioLogueado.idUsuario).subscribe({
      next: (response: any) => {
        if (response.estado == true) {
          this.listPinesAsignarGrid = response.pines;
          this.generarListaAsignarPines();
        }

        // this.loader_html = false;
        this.error_carga_inicial = false;
      }
    });
  }
   
  generarListaAsignarPines() {
    console.log('generarListaAsignarPines')
    this.elementos_tabla_asignados = [];
    for (let elem of this.listPinesAsignarGrid) {
      let pinesAsignar = {
        idSolicitudCreacionPines: elem.nroPin,
        usuario: elem.usuario,
        sitioWeb: elem.sitio,
        rut: elem.rut, 
        fechaAsignacion: this.formatoFecha(new Date(elem.fecha_Asignacion)), 
        descuento: elem.descuento,
        pin: elem.nroPin,
        mostrarPin: false
      }

      this.elementos_tabla_asignados.push(pinesAsignar);
    }
    console.log('asignar')
    console.log(this.elementos_tabla_asignados)
  }

  formatoFecha(fecha: Date) {
    const dia = ("0" + fecha.getDate()).slice(-2);
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    return (dia + '-' + mes + '-' + fecha.getFullYear());
  }

}
