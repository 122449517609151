import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-desplegable',
  templateUrl: './menu-desplegable.component.html',
  styles: [
  ]
})
export class MenuDesplegableComponent implements OnInit {
  @Input() tabs:any []=[];

  constructor() { }

  ngOnInit(): void {
  }

}
