import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SesionModel } from '../model/sesion.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private readonly http: HttpClient) { }

  async LoginOv(rutCompleto: string, sessionId: string): Promise<SesionModel> {
    let objetoSesion = await this.ObtenerToken(rutCompleto, sessionId) as SesionModel;
    objetoSesion.rut = rutCompleto;
    objetoSesion.sesionId = sessionId;
    objetoSesion.fechaInicioSesion = new Date();
    objetoSesion.fechaVencimientoToken = new Date();
    objetoSesion.fechaVencimientoToken.setMinutes(objetoSesion.fechaVencimientoToken.getMinutes() + environment.MINUTOS_VENCIMIENTO_TOKEN);

    return objetoSesion;
  }

  private async ObtenerToken(rutCompleto: string, sessionId: string) {

    let rut = rutCompleto.substr(0, rutCompleto.length - 1);
    let dv = rutCompleto.substr(rutCompleto.length - 1);

    return this.http.post<any>(environment.API + '/v1/login/loginov', { rut, sessionId, dv }).toPromise();
  }

  private async LoginSistemas(rutCompleto: string, sessionId: string) {

    let rut = rutCompleto.substr(0, rutCompleto.length - 1);
    let dv = rutCompleto.substr(rutCompleto.length - 1);

    return this.http.post<any>(environment.API + '/v1/login/LoginSistemas', { rut, sessionId, dv }).toPromise();
  }


}
