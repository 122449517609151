<!-- angular component
    privado > sitio privado
    simple > procesos, pasos, aplicativos
    corporativo  > sitio corporativo
    --->
<app-header [tipo]="'corporativo'"></app-header>

<!-- ******************** -->
<router-outlet></router-outlet>

<!-- angular component --->
<app-footer [tipo]="'simple'"></app-footer>
