import { Component, Input, OnInit } from '@angular/core';
import { GrupoModuloModel } from 'src/app/model/grupo-modulo.model';
import { SeccionModuloModel } from 'src/app/model/seccion-modulo.model';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]

})
export class HeaderComponent implements OnInit {
  @Input() tipo: string = 'privado';
  public nombreUsuario: string = "";
  public grupoModulos: SeccionModuloModel[] = [];
  public menuPrivadoStatus: boolean = false;

  constructor(private sesionService: SesionService) { }

  ngOnInit(): void {
    if(this.sesionService.EstaLogueado()){
      let sesion = this.sesionService.ObtenerSesion();
      this.grupoModulos = sesion.modulosUsuario;  
      this.nombreUsuario = sesion.nombreCompleto;
      console.log(this.grupoModulos);
    }
  }
  activarMenuPrivado() {
    this.menuPrivadoStatus = this.menuPrivadoStatus ? false : true;
  }
  activarMenuPrivado_res(e: any) {

    this.menuPrivadoStatus = e;

  }
}
