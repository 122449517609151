<div class="bs-separador h-30"></div>

<div class="bs-contenedor" style="min-width: 90%;">
    <!-- <div class="row">
        <div class="col-12">
            <span class="bs-chip">Pines asignados cliente</span>
            <div class="bs-separador h-30"></div>
        </div>
    </div> -->

    <div class="bs-pestanas">
        <p-tabView>
            <p-tabPanel header="Pines Asignados cliente">
                <ng-template pTemplate="content">
                    <div class="bs-separador h-30"></div>
                    <div class="col-12">
                        <div class="bs-tabla-datos">
                            <p-table [value]="elementos_tabla_asignados"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} datos"
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th rowspan="2" width="10%" pSortableColumn="etiqueta" scope="col">
                                            <span>Empresa</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="10%" pSortableColumn="cantidad" scope="col">
                                            <span>Código pin</span>
                                            <p-sortIcon field="cantidad"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="10%" pSortableColumn="etiqueta" scope="col">
                                            <span>Valor de descuento (%)</span>
                                        <!-- <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                                            <span>Fecha de creación</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th> -->
                                        <th rowspan="2" width="10%" pSortableColumn="etiqueta" scope="col">
                                            <span>Fecha de inicio</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="10%" pSortableColumn="etiqueta" scope="col">
                                            <span>Fecha de termino</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="15%" pSortableColumn="etiqueta" scope="col">
                                            <span>Ejecutivo</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>                                        
                                        <th rowspan="2" width="30%" pSortableColumn="etiqueta" scope="col">
                                            <span>Opciones</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-i="index">
                                    <tr>
                                        <td>{{ data.empresaNombre }}</td>
                                        <td>{{ data.nroPin }}</td>
                                        <td>{{ data.descuento }}</td>
                                        <td>{{ data.fechaCaducidadDesde | dateFormat }}</td>
                                        <td>{{ data.fechaCaducidadHasta  | dateFormat}}</td>
                                        <td>{{ data.usuario }}</td>
                                        <!-- <td>{{ data.pin }}</td> -->
                                        <td>
                                            <div class="bs-tabla-panel row" style="text-align: center;width: 100%;">
                                                
                                                <div>
                                                    <button class="bs-btn bs-btn-secondary tabla"
                                                    (click)="mostrarEditar(data)">
                                                        Editar
                                                    </button>
                                                    <button class="bs-btn bs-btn-primary tabla" 
                                                    (click)="mostrarBitacora(data)">
                                                        Bitacora
                                                    </button>

                                                    <button class="bs-btn bs-btn-danger tabla" 
                                                    (click)="habilitar(data, false)">
                                                        Deshabilitar
                                                    </button>
                                                </div> 

                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<app-lista-bitacora [pinSeleccionado]="pinSeleccionado" [(abrirModal)]="mostrarModalBitacora" *ngIf="mostrarModalBitacora"></app-lista-bitacora>
<app-editar-pin (alCerrarModal)="obtenerPines()" [pinSeleccionado]="pinSeleccionado" [(abrirModal)]="mostrarModalEditar" *ngIf="mostrarModalEditar"></app-editar-pin>