import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComponentesComponent } from './modules/layout/components/componentes/componentes.component';

import { OvComponent } from './modules/login/pages/ov/ov.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { CrearPinesGeneralComponent } from './modules/creacion-pines/pages/crear-pines-general/crear-pines-general.component';
import { DerivarPinComponent } from './modules/administracion-pin/pages/derivar-pin/derivar-pin.component';
import { PinesAsignadosCotizadoresComponent } from './modules/administracion-pin/pages/pines-asignados-cotizadores/pines-asignados-cotizadores.component';
import { PinesAsignadosConvenioComponent } from './modules/administracion-pin/pages/pines-asignados-convenio/pines-asignados-convenio.component';
import { PinesAsignadosClienteComponent } from './modules/administracion-pin/pages/pines-asignados-cliente/pines-asignados-cliente.component';
import { ReportePinesComponent } from './modules/reportes/pages/reporte-pines/reporte-pines.component';
import { ConvenioPinesComponent } from './modules/convenio-zenit/pages/convenio-pines/convenio-pines.component';
import { ConvenioPinesBciComponent } from './modules/convenio-bci/pages/convenio-pines-bci/convenio-pines-bci.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'crear-pines',
    pathMatch: 'full',
  },
  {
    path:'',
    component: ComponentesComponent,
    children:[
      {
        path: 'crear-pines',
        component: CrearPinesGeneralComponent
      },
      {
        path: 'crear-pines-transversal',
        component: CrearPinesGeneralComponent,
        pathMatch: 'full',
      },
      {
        path: 'reporte-pines',
        component: ReportePinesComponent
      },
      {
        path: 'derivar-pin',
        component: DerivarPinComponent
      },      
      {
        path: 'pines-asignados-cotizadores',
        component: PinesAsignadosCotizadoresComponent
      },      
      {
        path: 'pines-asignados-convenio',
        component: PinesAsignadosConvenioComponent
      },      
      {
        path: 'pines-asignados-cliente',
        component: PinesAsignadosClienteComponent
      }
      ,
      {
        path: 'convenio-pines',
        component: ConvenioPinesComponent
      }, 
      {
        path: 'convenio-pines-bci',
        component: ConvenioPinesBciComponent
      }, 
    ]
  },
  {
    path: 'ov',
    component: OvComponent,
    pathMatch: 'full',
  },
  {
    path: 'error/:id',
    component: ErrorComponent,
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
