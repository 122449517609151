import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pine } from '../model/pines';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SolicitarPinesServiceService {
  //private readonly API = 'https://localhost:7164/api';  

  constructor(private readonly http: HttpClient) { }

  getRegistroSolicitudesPaginadas(idInicio: string, idFin: string) {
    return this.http.get<any>(
      environment.API + '/v1/SolicitarPin/ObtenerRegistroSolicitudesPaginadas',
      {
        params: {
          IdInicio: idInicio,
          IdFin: idFin,
        }
      })
  }

  getListaPines(cantidadRegistro: any) {
    return this.http.get<any>(
      environment.API + '/v1/SolicitarPin/ObtenerConfiguracionSolicitudData',
      {
        params: {
          RegistrosPorPantalla: cantidadRegistro,
        }
      })
  }

  async createPine(pine: Pine) {
    const body = {
      idUsuarioCreador: pine.idUsuarioCreador,
      cantidad: pine.cantidad,
      porcentajeDescuento: pine.porcentajeDescuento,
      estado: pine.estado,
      idUsuarioComercial: pine.idUsuarioComercial,
      idSistema: pine.idSistema,
      fechaCaducidadDesde: pine.fechaCaducidadDesde,
      fechaCaducidadHasta: pine.fechaCaducidadHasta,
      idAccion: pine.idAccion,
      idEmpresa: pine.idEmpresa,
      archivoConRutClientesConPin: null,
      nroPinGenerico: pine.nroPinGenerico,
      idConvenio: pine.idConvenio ?? null
      //fechaSolicitud: pine.fechaSolicitud,
      //generaPinesDeInmediato: 0

    };

    return this.http.post<any>(
      environment.API + '/v1/SolicitarPin/CrearSolicitud', body).toPromise();
  }

  async desactivarPin(idPin: string) {
    return this.http.put<any>(
      environment.API + `/v1/SolicitarPin/DesactivarSolicitud?IdSolicitudCreacionPines=${idPin}`,
      {
        params: {
          NroPin: idPin
        }
      }).toPromise();
  }

}
