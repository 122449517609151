import { Component, OnInit } from '@angular/core';
import { SesionModel } from 'src/app/model/sesion.model';
import { ComplementosServiceService } from 'src/app/services/complementos-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { PinesServiceService } from 'src/app/services/pines-service.service';
import { SesionService } from 'src/app/services/sesion.service';

@Component({
  selector: 'app-pines-asignados-cliente',
  templateUrl: './pines-asignados-cliente.component.html',
  styleUrls: ['./pines-asignados-cliente.component.css']
})
export class PinesAsignadosClienteComponent implements OnInit {

  public sesion: SesionModel;
  public elementos_tabla_asignados: any[];
  public pinSeleccionado: number = 0;
  public mostrarModalBitacora: boolean = false;
  public mostrarModalEditar: boolean = false;


  constructor(
    private servicePines: PinesServiceService,
    private sesionService: SesionService,
    private loaderService: LoaderService,
    private modalService: ModalService
  ) {
  }
  async ngOnInit() {
    this.loaderService.show(0, "Cargando datos de pines...");
    this.sesion = this.sesionService.ObtenerSesion();
    await this.obtenerPines();
    this.loaderService.close();
  }

  async obtenerPines() {
    let valores = await this.servicePines.getPinesAsignadosCliente(this.sesion.idUsuario.toString());
    this.elementos_tabla_asignados = valores.pines;
  }

  mostrarBitacora(pin: any) {
    this.pinSeleccionado = pin;
    this.mostrarModalBitacora = true;
  }

  mostrarEditar(pin: any) {
    this.pinSeleccionado = pin;
    this.mostrarModalEditar = true;
  }

  async habilitar(pin: any, habilitar: boolean) {
    this.modalService.confirm("¿Desea deshabilitar el pin?", async () => {
      this.loaderService.show(0,"Cambiando de estado...");
      let resultado = await this.servicePines.cambiarEstadoPinPorId(pin.idPin.toString(), habilitar);
      this.loaderService.close();

      if (resultado.estado == true){
        this.modalService.alert("Pin deshabilitado con éxito");
        await this.obtenerPines()
      }
      else
        this.modalService.alert("No se pudo realizar la operación","Información","error");
    });
  }

}
