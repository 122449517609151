import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {


  @Input()
  loader_html = false;

  @Input()
  texto = 'Cargando...';


}
