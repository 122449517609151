import { NgModule } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from "@angular/common/locales/es-CL";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContainersModule } from './containers.module';
import { ComponentsModule } from './components.module';
import { PrimeNGModule } from './shared/components/prime-ng/prime-ng.module';

import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';
import { LoginModule } from './modules/login/login.module';
import { MenuLateralComponent } from './modules/layout/components/menu-lateral/menu-lateral.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { CreacionPinesModule } from './modules/creacion-pines/creacion-pines.module';
import { FormatRutPipe } from './pipes/format-rut.pipe';
import { AdministracionPinModule } from './modules/administracion-pin/administracion-pin.module';
import { ReportesModule } from './modules/reportes/reportes.module';
import { ConvenioModule } from './modules/convenio-zenit/convenio.module';
import { ConvenioBciModule } from './modules/convenio-bci/convenio-bci.module';

registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    PrimeNGModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ContainersModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    CreacionPinesModule,
    AdministracionPinModule,
    ReportesModule,
    ConvenioModule,
    ConvenioBciModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
