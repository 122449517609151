import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { LoaderComponent } from '../shared/components/loader/loader.component';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private componentRef!: ComponentRef<LoaderComponent>;
  private timeOut: number = 0;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  show(timeOut: number = 0, texto: string = 'Cargando...') {
    this.timeOut = timeOut;

    if (!this.componentRef) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);

      this.componentRef = componentFactory.create(this.injector);
      this.componentRef.instance.loader_html = true;
      this.componentRef.instance.texto = texto;

      this.appRef.attachView(this.componentRef.hostView);

      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }else{
      this.componentRef.instance.loader_html = true;
      this.componentRef.instance.texto = texto;
      this.appRef.attachView(this.componentRef.hostView); 
    }
  }

  close(): void {
    setTimeout(() => {
      this.componentRef.instance.loader_html = false;   
    }, this.timeOut);
  }
}