
<div class="bs-contenedor" style="min-width: 90%;">

  <p-tabView>

    <p-tabPanel header="Bolsa de pines">
      <div class="bs-separador h-30"></div>

      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-3">
            <label for="form-15" class="bs-forms">
              <h5>Sito web</h5>
              <p-dropdown formControlName="sitioWeb" id="tipoSitioWeb" [options]="datos_sitio_web"
                [(ngModel)]="tipo_sitio_web" name="tipoSitioWeb" placeholder="Seleccione sitio web" optionLabel="nombre"
                optionValie="id" [showClear]="false" emptyMessage="Sin Datos"></p-dropdown>
              <div *ngIf="submitted && controlsFormErros.sitioWeb.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.sitioWeb.errors.required">Campo requerido</div>
              </div>
            </label>
          </div>

          <div class="col-12 col-sm-6 col-lg-3">
            <label for="form-15" class="bs-forms">
              <h5>Usuario comercial</h5>
              <p-dropdown formControlName="usuarioComercial" [filter]="true" id="tipocontacto" [options]="datos"
                [(ngModel)]="tipo_datos" name="tipocontacto" placeholder="Seleccione usuario" optionLabel="nombre"
                optionValie="id" [showClear]="false" emptyMessage="Sin Datos" [autoDisplayFirst]="false"
                filterPlaceholder="Buscar un item " emptyFilterMessage="Sin datos"
                (onChange)="onChangeUsuarioComercial()"></p-dropdown>
              <div *ngIf="submitted && controlsFormErros.usuarioComercial.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.usuarioComercial.errors.required">Campo requerido
                </div>
                <div class="message-invalide" *ngIf="controlsFormErros.usuarioComercial.errors.pattern">Ingrese solo
                  letras</div>
              </div>
            </label>
          </div>

          <!--<div class="col-12 col-md-6 col-lg-3">
              <label for="form-1" class="bs-forms">
                <h5>Usuario Comercial</h5>
                <input type="text" id="form-1" formControlName="usuarioComercial" placeholder="Placeholder text" pInputText
                  [ngClass]="{ 
                    'ng-invalid': 
                    registerForm.get('usuarioComercial')?.touched || 
                    (registerForm.get('usuarioComercial')?.dirty && 
                    !registerForm.get('usuarioComercial')?.valid)
                  }" />
                <div *ngIf="submitted && f.usuarioComercial.errors" class="invalid-feedback">
                  <div class="message-invalide" *ngIf="f.usuarioComercial.errors.required">Campo Requerido</div>
                  <div class="message-invalide" *ngIf="f.usuarioComercial.errors.pattern">Ingrese solo letras</div>
                </div>
              </label>
            </div>-->

          <div class="col-12 col-md-6 col-lg-3">
            <label for="form-1" class="bs-forms">
              <h5>Rut usuario comercial</h5>
              <input type="text" formControlName="rutUsuarioComercial" placeholder="Seleccione usuario comercial"
                pInputText id="form-1" />
              <div *ngIf="submitted && controlsFormErros.rutUsuarioComercial.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.rutUsuarioComercial.errors.required">Campo
                  requerido</div>
              </div>
            </label>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <label for="form-1" class="bs-forms">
              <h5>Descuento</h5>
              <input type="text" formControlName="descuento" placeholder="Descuento" pInputText id="form-1" />
              <div *ngIf="submitted && controlsFormErros.descuento.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.descuento.errors.required">Campo requerido</div>
                <div class="message-invalide" *ngIf="controlsFormErros.descuento.errors.pattern">Ingrese solo números
                </div>
                <div class="message-invalide" *ngIf="controlsFormErros.descuento.errors.min">Valor minimo es 0</div>
                <div class="message-invalide" *ngIf="controlsFormErros.descuento.errors.max">Valor maximo es 90</div>
              </div>
            </label>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <label for="form-1" class="bs-forms">
              <h5>Cantidad</h5>
              <input type="text" formControlName="cantidad"
                [ngClass]="tipo_deriva?.id == idDescuentoGenerico? 'disabled':''" placeholder="Cantidad" pInputText
                id="form-1" [(ngModel)]="cantidad" />
              <div *ngIf="submitted && controlsFormErros.cantidad.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.cantidad.errors.required">Campo requerido</div>
                <div class="message-invalide" *ngIf="controlsFormErros.cantidad.errors.pattern">Ingrese solo números
                </div>
                <div class="message-invalide" *ngIf="controlsFormErros.cantidad.errors.min">Valor minimo es 0</div>
              </div>
            </label>
          </div>

          <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <label for="form-1411" class="bs-forms">
              <h5>Vigencia desde</h5>
              <p-calendar formControlName="vigenciaDesde" [(ngModel)]="vigenciaDesde" [firstDayOfWeek]="1"
                inputId="form-1411" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
              <div *ngIf="submitted && controlsFormErros.vigenciaDesde.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.vigenciaDesde.errors.required">Campo requerido
                </div>
                <div class="message-invalide" *ngIf="controlsFormErros.vigenciaDesde.errors.fechaDesdeHoyValidator">
                  Fecha minima es hoy</div>
              </div>
            </label>
          </div>

          <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <label for="form-1412" class="bs-forms">
              <h5>Vigencia hasta</h5>
              <p-calendar formControlName="vigenciaHasta" [(ngModel)]="vigenciaHasta" [firstDayOfWeek]="1"
                inputId="form-1412" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
              <div *ngIf="submitted && controlsFormErros.vigenciaHasta.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.vigenciaHasta.errors.required">Campo requerido
                </div>
                <div class="message-invalide" *ngIf="controlsFormErros.vigenciaHasta.errors.fechaHastaMinimaValidator">
                  Vigencia hasta es menor a vigencia desde</div>
              </div>
            </label>
          </div>

          <div class="col-12 col-sm-6 col-lg-3">
            <label for="form-15" class="bs-forms">
              <h5>Tipo pin</h5>
              <p-dropdown formControlName="derivaAsigna" id="tipoDeriva" [options]="datos_Deriva"
                [(ngModel)]="tipo_deriva" (onChange)="cambiaDerivaAsigna()" name="tipoDeriva"
                placeholder="Seleccione opción" optionLabel="nombre" optionValie="id" [showClear]="false"
                emptyMessage="Sin Datos"></p-dropdown>
              <div *ngIf="submitted && controlsFormErros.derivaAsigna.errors" class="invalid-feedback">
                <div class="message-invalide" *ngIf="controlsFormErros.derivaAsigna.errors.required">Campo Requerido
                </div>
              </div>
            </label>
          </div>

          <div class="col-12 col-sm-6 col-lg-3" *ngIf="tipo_deriva?.id == idDescuentoGenerico">
            <label for="form-15" class="bs-forms">
              <h5>Número de Pin</h5>
              <input type="text" formControlName="numeroPin" placeholder="Número de pin" pInputText id="form-1"
                [(ngModel)]="nroPin" />
              <div *ngIf="submitted && !nroPin" class="invalid-feedback">
                <div class="message-invalide" *ngIf="!nroPin">Campo requerido</div>
              </div>
            </label>
          </div>

          <div class="col-12 col-sm-6 col-lg-3" *ngIf="tipo_deriva?.id == idDescuentoGenerico">
            <label for="form-15" class="bs-forms">
              <h5>Convenio</h5>
              <p-dropdown formControlName="convenio" id="convenio" [options]="convenios" [(ngModel)]="convenio" name="convenio"
                placeholder="Seleccione opción" optionLabel="nombreConvenio" optionValie="idInterno"></p-dropdown>
              <div *ngIf="submitted && !convenio && tipo_deriva?.id == idDescuentoGenerico" class="invalid-feedback">
                <div class="message-invalide">Campo Requerido
                </div>
              </div>
            </label>
            </div>
            </div>

        <div class="row btn-right">
          <button class="bs-btn bs-btn-primary col-12 col-md-6 col-lg-3">Guardar</button>
          <!--<div class="col-12 col-md-6 col-lg-3">
              <a href="#" class="bs-btn bs-btn-primary">Guardar</a>
            </div>-->
          <!--<button class="bs-btn bs-btn-primary col-12 col-md-6 col-lg-3" type="reset" (click)="onReset()">Cancel</button>-->
        </div>
      </form>

      <div class="bs-separador h-30"></div>
      <div class="col-12">
        <div class="bs-tabla-datos">
          <p-table [value]="elementos_tabla"
            currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} datos"
            [paginator]="true" [rows]="10" [showCurrentPageReport]="true">
            <ng-template pTemplate="header">
              <tr>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Empresa</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th id="idCantidad" rowspan="2" width="110" pSortableColumn="cantidad" hidden scope="col">
                  <span>ID</span>
                  <p-sortIcon field="cantidad"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="cantidad" scope="col">
                  <span>Cantidad</span>
                  <p-sortIcon field="cantidad"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Cant. Asing. Ejecutivo</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Porcentaje</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Creado</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Estado</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="130" pSortableColumn="etiqueta" scope="col">
                  <span>Rut Creador</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Nombre Creador</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="130" pSortableColumn="etiqueta" scope="col">
                  <span>Rut Asignado</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Nombre Asignado</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Sitio Web</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Desde</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Hasta</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                  <span>Acción</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
                <th rowspan="2" width="130" pSortableColumn="etiqueta" scope="col">
                  <span>Opciones</span>
                  <p-sortIcon field="etiqueta"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i="index">
              <tr>
                <td>
                  <img
                    src="https://play-lh.googleusercontent.com/n9LjvPWCGCs4n1QfsM2VzSco8SB42T52EgwRBZ9wtR8eD1YWyu1vFShtrseiYiRp2Q=w240-h480-rw"
                    id="Image1" alt="" height="15" width="15">
                </td>
                <td hidden>{{ data.id }}</td>
                <td>{{ data.cantidad }}</td>
                <td>{{ data.cantAsingEjecutivo }}</td>
                <td>{{ data.porcentaje }}</td>
                <td>{{ data.fechaCreacion }}</td>
                <td>{{ data.estado }}</td>
                <td>{{ data.creadorRut | formatRut }}</td>
                <td>{{ data.creadorNombre }}</td>
                <td>{{ data.asignadoRut | formatRut }}</td>
                <td>{{ data.asignadoNombre }}</td>
                <td>{{ data.sitioWeb }}</td>
                <td>{{ data.fechaDesde }}</td>
                <td>{{ data.fechaHasta }}</td>
                <td>{{ data.accion }}</td>
                <td>
                  <div class="bs-tabla-panel" *ngIf="data.estado=='Activo'">
                    <button class="bs-btn bs-btn-secondary tabla" (click)="desactivar_pin(data.id)">Desactivar</button>
                    <!--<a class="bs-btn bs-btn-secondary tabla"> Desactivar </a>-->
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>



      <p-dialog [modal]="true" [draggable]="false" [dismissableMask]="true" [resizable]="false"
        [(visible)]="modal_mostrar" class="bs-modal w-500">
        <ng-template pTemplate="header">
          <h2 class="bs-azul bs-center"><strong>{{modal_msj_principal}}</strong></h2>
        </ng-template>
        <ng-template pTemplate="content">
          <p class="bs-center">
            <strong class="bs-negro">{{modal_msj_secundario}} </strong>
          </p>
          <div class="bs-separador h-10"></div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="simple">
            <button type="reset" class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-primary"
              [ngClass]="{'bs-btn-error': error_carga_inicial, 'bs-btn-verde': !error_carga_inicial}"
              (click)="aceptar_modal()">{{modal_boton_nombre}}</button>
          </div>
        </ng-template>
      </p-dialog>
    </p-tabPanel>
  </p-tabView>

  <!-- <div class="row">
      <div class="col-12">
        <span class="bs-chip">Bolsa de pines</span>
        <div class="bs-separador h-30"></div>
      </div>
    </div> -->




</div>