<div class="bs-page-loader" *ngIf="!error">
    <span class="bs-loader-html w-48"></span>
    <p>Iniciando sesión</p>
</div>

<p-dialog [(visible)]="error" [draggable]="false" [resizable]="false" [modal]="false" [dismissableMask]="true"
class="bs-modal w-500" [closable]="false">
<ng-template pTemplate="header">
  <h2 class="bs-azul bs-center">
    <strong>Error</strong>
  </h2>
</ng-template>
<ng-template pTemplate="content">
  <p class="bs-center">
    <strong class="bs-negro">Error al iniciar sesión </strong>
  </p>

  <div class="bs-separador h-10"></div>
</ng-template>
</p-dialog>