<p-dialog [visible]="true" [draggable]="false" [resizable]="false" [modal]="false" [dismissableMask]="true"
    class="bs-modal w-500" [closable]="false">
    <ng-template pTemplate="header">
        <h2 class="bs-azul bs-center">
            <strong>Error {{codigo}}</strong>
        </h2>
    </ng-template>
    <ng-template pTemplate="content">
        <p class="bs-center">
            <strong class="bs-negro">{{mensaje}} </strong>
        </p>

        <div class="bs-separador h-10"></div>
    </ng-template>
</p-dialog>