<header class="bs-header" [ngClass]="tipo" *ngIf="tipo == 'simple'">
  <div class="bs-contenedor">
    <a href="#" class="logo">
      <img src="assets/img/svg/logo_bciseguros.svg" alt="Logo Bciseguros" />
    </a>
    <h1 class="bs-contenido">SOLICITUD DE REEMBOLSO</h1>

    <img
      class="floating-img"
      src="assets/img/svg/puntos.svg"
      alt="Puntos corporativos"
    />

    <a href="#" class="resumen">
      <figure>
        <img src="assets/img/svg/icon-resumen.svg" alt="icono resumen" />
      </figure>
      <span> Resumen </span>
    </a>
  </div>
</header>
<header class="bs-header" [ngClass]="tipo" *ngIf="tipo == 'privado'">
  <div class="bs-contenedor extended">
    <button
      class="menu"
      (click)="activarMenuPrivado()"
      [ngClass]="{ active: menuPrivadoStatus }"
    ></button>

    <a href="#" class="logo">
      <img
        src="assets/img/svg/logo_bciseguros.svg"
        alt="Logo Bciseguros compuesto"
      />
    </a>
    <div class="actions">
      <a class="action" title="Lorem ipdum">
        <figure>
          <img src="assets/img/svg/icon-fono.svg" alt="teléfono" />
        </figure>
      </a>
      <a class="action" title="Lorem ipdum">
        <figure>
          <img src="assets/img/svg/icon-notification.svg" alt="Notificacion" />
        </figure>
        <span class="bubble azul">3</span>
      </a>
      <div class="action menu">
        <a title="Lorem ipdum">
          <figcaption>
            <p>Bienvenido</p>
            <h4>Carlos Ahumada López</h4>
          </figcaption>
          <figure>
            <img src="assets/img/svg/icon-avatar.svg" alt="Usuario" />
          </figure>
        </a>
      </div>

    </div>
  </div>
</header>
<header class="bs-header" [ngClass]="tipo" *ngIf="tipo == 'corporativo'">
  <div class="top">
    <div class="bs-contenedor extended">
      <app-menu-lateral [hidden]="!menuPrivadoStatus" [(menuPrivadoStatus)]="menuPrivadoStatus" [seccionesModulos]="grupoModulos"></app-menu-lateral>
      <button style="display: block;"
        class="menu"
        (click)="activarMenuPrivado()"
        [ngClass]="{ active: menuPrivadoStatus }"
      ></button>
      <!-- <app-menu-lateral [(menuPrivadoStatus)]="menuPrivadoStatus" [grupoModulos]="grupoModulos"></app-menu-lateral> -->
      <a href="#" class="logo">
        <img
          src="assets/img/svg/logo_bciseguros.svg"
          alt="Logo Bciseguros compuesto"
        />
      </a>
      <div class="actions">
        
        <div class="action menu">
          <a title="Lorem ipdum">
            <figcaption>
              <p>Bienvenido</p>
              <h4>{{nombreUsuario}}</h4>
            </figcaption>
            <figure>
              <img src="assets/img/svg/icon-avatar.svg" alt="Usuario" />
            </figure>
          </a>
        </div>
  
      </div>
    </div>
    
    
  </div>
  <!-- <div class="bottom">
    <div class="bs-contenedor extended">
      <ul>

        <li *ngFor="let grupo of grupoModulos">
          <a title="Lorem ioim">{{grupo.nombreGrupoModulo}}</a> 
          <app-menu-desplegable [tabs]="grupo.modulos"></app-menu-desplegable>
        </li>
      </ul>
    </div>
  </div> -->
</header>
<!---->
<div class="bs-separador h-60" *ngIf="tipo == 'privado'"></div>
<div class="bs-separador h-90" *ngIf="tipo == 'corporativo'"></div>
<div class="bs-separador h-60" *ngIf="tipo == 'simple'"></div>
