<p-dialog [(visible)]="abrirModal" [closable]="false" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
    [modal]="true" [dismissableMask]="true" class="bs-modal w-1000">
    <ng-template pTemplate="header">
    </ng-template>
    <ng-template pTemplate="content">
        <p class="bs-center">
        </p>
        <p-tabView>
            <p-tabPanel header="Editar Descuentos">
                <br>
                <label for="form-1" class="bs-forms">
                    <label>
                        Habilitar Pines
                    </label>
                    <input type="checkbox" [ngModel]="habilitaPines" [checked]="habilitaPines === 1 ? true : false" (change)="onCheckboxChange($event)">
                </label>
                <br>
                <div *ngFor="let pin of pines; let i = index">
                    <label for="form-1" class="bs-forms">
                        <div class="col-12 row">
                            <div class="col-md-6">
                                <input type="text" maxlength="2" dirOnlyNumber [ngModel]="pin" value="pin" pInputText id="form-1" (change)="onTextboxChange($event, i)"/>
                            </div>
                            <div class="col-md-6">
                                <button class="bs-btn btn-eliminar-zenit" (click)="removePin(pin)">Eliminar</button>
                            </div>
                        </div>
                    </label>
                </div>
                <button type="button" class="bs-btn bs-btn-primary" (click)="addPin()">Agregar</button>
            </p-tabPanel>
        </p-tabView>

        <div class="bs-separador h-10"></div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="simple">
            <button type="button" class="bs-btn  bs-btn-secondary" (click)="cerrarModal(false)">Cancelar</button>
            <button type="button" class="bs-btn bs-btn-primary" style="visibility:hidden"></button>
            <button type="button" class="bs-btn bs-btn-primary" (click)="cerrarModal(true)">Guardar</button>
        </div>
    </ng-template>
</p-dialog>