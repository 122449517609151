<p-dialog [(visible)]="abrirModal" [closable]="false" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
    [modal]="true" [dismissableMask]="true" class="bs-modal w-1000">
    <ng-template pTemplate="header">
        <!-- <h2 class="bs-azul bs-center">
            <strong>Editar pin</strong>
        </h2> -->
    </ng-template>
    <ng-template pTemplate="content">
        <p class="bs-center">
        </p>
        <p-tabView>
            <p-tabPanel header="Editar pin">
                <ng-template pTemplate="content">
                    <div class="bs-separador h-30"></div>
                    <div class="col-12 row">
                        <div class="col-md-6 col-lg-3">
                            <label for="form-1" class="bs-forms">
                                <h5>Descuento (%)</h5>
                                <!-- INPUT ASOCIADO A PRIME NG -->
                                <input maxlength="2" dirOnlyNumber type="text" [(ngModel)]="pinSeleccionado.descuento"
                                    pInputText id="form-1" />
                                <div *ngIf="submitted && !pinSeleccionado.descuento" class="invalid-feedback">
                                    <div class="message-invalide">Campo requerido</div>
                                </div>
                            </label>

                        </div>

                        <div class="col-12 col-sm-6 col-lg-3">
                            <label for="form-15" class="bs-forms">
                                <h5>Usuario comercial</h5>
                                <p-dropdown [filter]="true" id="idUsuario" [options]="usuarios"
                                    [(ngModel)]="usuarioSeleccionado" name="nombre" placeholder="Seleccione usuario"
                                    optionLabel="nombre" optionValie="idUsuario" [showClear]="false"
                                    emptyMessage="Sin Datos" [autoDisplayFirst]="false" inputId="idUsuario"
                                    filterPlaceholder="Buscar un item " emptyFilterMessage="Sin datos"
                                    (ngModelChange)="onChangeUsuarioComercial($event)"></p-dropdown>
                                <div *ngIf="submitted && !usuarioSeleccionado" class="invalid-feedback">
                                    <div class="message-invalide">Campo requerido</div>
                                </div>
                            </label>
                        </div>

                        <div class="col-sm-6 col-lg-4 col-xl-3">
                            <label for="form-1411" class="bs-forms">
                                <h5>Vigencia Desde</h5>
                                <!-- INPUT ASOCIADO A PRIME NG -->
                                <p-calendar [(ngModel)]="pinSeleccionado.fechaCaducidadDesde" id="fechaDesde"
                                    name="fechaDesde" [firstDayOfWeek]="1" inputId="form-1411" [showIcon]="true"
                                    dateFormat="dd/mm/yy" placeholder="dd-mm-yyyy"></p-calendar>
                                <div *ngIf="submitted && !pinSeleccionado.fechaCaducidadDesde" class="invalid-feedback">
                                    <div class="message-invalide">Campo requerido</div>
                                </div>
                            </label>
                        </div>

                        <div class="col-sm-6 col-lg-4 col-xl-3">
                            <label for="form-4444" class="bs-forms">
                                <h5>Vigencia Hasta</h5>
                                <!-- INPUT ASOCIADO A PRIME NG -->
                                <p-calendar [(ngModel)]="pinSeleccionado.fechaCaducidadHasta" id="fechaHasta"
                                    name="fechaHasta" [firstDayOfWeek]="1" inputId="form-4444" [showIcon]="true"
                                    dateFormat="dd/mm/yy" placeholder="dd-mm-yyyy"></p-calendar>
                                <div *ngIf="submitted && !pinSeleccionado.fechaCaducidadHasta" class="invalid-feedback">
                                    <div class="message-invalide">Campo requerido</div>
                                </div>
                            </label>
                        </div>

                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>

        <div class="bs-separador h-10"></div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="simple">
            <button type="button" class="bs-btn  bs-btn-secondary" (click)="cerrarModal(false)">Cancelar</button>
            <button type="button" class="bs-btn bs-btn-primary" style="visibility:hidden"></button>
            <button type="button" class="bs-btn bs-btn-primary" (click)="cerrarModal(true)">Guardar</button>
        </div>
    </ng-template>
</p-dialog>