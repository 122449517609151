export class Pine {
    constructor(
        public idUsuarioCreador: number,
        public fechaSolicitud: Date,
        public cantidad: number,
        public porcentajeDescuento: number,
        public estado: number,
        public idUsuarioComercial: number,
        public idSistema: number,
        public fechaCaducidadDesde: Date,
        public fechaCaducidadHasta: Date,
        public idAccion: number,
        public idEmpresa: number,
        public nroPinGenerico: string,
        public idConvenio: string
    ) { }
}