import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PrimeNGModule } from './shared/components/prime-ng/prime-ng.module';
import { HeaderComponent } from './modules/layout/components/header/header.component';
import { FooterComponent } from './modules/layout/components/footer/footer.component';
import { MenuDesplegableComponent } from './modules/menu-desplegable/menu-desplegable.component';
import { MenuLateralComponent } from './modules/layout/components/menu-lateral/menu-lateral.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { FormatRutPipe } from './pipes/format-rut.pipe';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { DateFormatPipe } from './pipes/fotmat-date.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { ModalComponent } from './shared/components/modal/modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuDesplegableComponent,
    MenuLateralComponent,
    ErrorComponent,
    FormatRutPipe,
    LoaderComponent    ,
    DateFormatPipe,
    OnlyNumberDirective,
    ModalComponent
  ],
  imports: [
    PrimeNGModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    FormatRutPipe,
    DateFormatPipe,
    OnlyNumberDirective
  ]
})
export class ComponentsModule { }
