<footer class="bs-footer" [ngClass]="tipo" *ngIf="tipo=='simple'">
  <div class="bs-wrapper">
      <div class="simple-content">
        <!-- <div class="marca">
          <a href="#" class="logo">
           <img src="assets/img/svg/logo_bciseguros.svg" alt="Logo Bciseguros">
          </a>
          <p class="d-none d-sm-block">	&copy; Bci Seguros {{fecha.getFullYear()}} </p>
        </div> -->

       <!-- <div class="redes-sociales">
           <p><strong>Síguenos en redes</strong></p>
           <a href="" title="Ir a Facebook"><img src="assets/img/svg/icon_facebook.svg" alt="Facebook"></a>
           <a href="" title="Ir a Twitter"><img src="assets/img/svg/icon_twitter.svg" alt="Twitter"></a>
           <a href="" title="Ir a instagram"><img src="assets/img/svg/icon_instagrams.svg" alt="instagram"></a>
           <a href="" title="Ir a youtube"><img src="assets/img/svg/icon_instagram.svg" alt="youtube"></a>
       </div> -->
       <!--<p class="d-block d-sm-none">	&copy; Bci Seguros {{fecha.getFullYear()}} </p>-->
      </div>


  </div>
  <div class="bs-separador h-10"></div>
</footer>
