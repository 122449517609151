import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ConvenioPinesService {

  constructor(private readonly http: HttpClient) { }


  public async ObtenerConveniosPinesZenit() {
    return this.http.get<any>(environment.API + '/v1/ConveniosZenit/ConsultaConvenios', {}).toPromise();
  }

  public async ObtenerPinesByConvenioRecursoZenit(idConvenio: any, idRecurso: any) {
    return this.http.get<any>(environment.API + '/v1/ConveniosZenit/ConsultaPinesConvenio?idConvenio=' + idConvenio + "&idRecurso=" + idRecurso, {}).toPromise();
  }

  public async ActualizarPinesConvenioRecursoZenit(param: any) {
    return this.http.put<any>(environment.API + '/v1/ConveniosZenit/ActualizarPinesConvenioRecurso', param).toPromise();
  }



  public async ObtenerConveniosPinesBci() {
    return this.http.get<any>(environment.API + '/v1/ConveniosBci/ConsultaConvenios', {}).toPromise();
  }

  public async ObtenerPinesByConvenioRecursoBci(idConvenio: any, idRecurso: any) {
    return this.http.get<any>(environment.API + '/v1/ConveniosBci/ConsultaPinesConvenio?idConvenio=' + idConvenio + "&idRecurso=" + idRecurso, {}).toPromise();
  }

  public async ActualizarPinesConvenioRecursoBci(param: any) {
    return this.http.put<any>(environment.API + '/v1/ConveniosBci/ActualizarPinesConvenioRecurso', param).toPromise();
  }


}
