import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrupoModuloModel } from 'src/app/model/grupo-modulo.model';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { SeccionModuloModel } from 'src/app/model/seccion-modulo.model';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent {

  @Input() menuPrivadoStatus : boolean = false;
  @Input()  seccionesModulos: SeccionModuloModel[] = [];

  @Output() menuPrivadoStatusChange = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) { } 

  closeMenu(){
    this.menuPrivadoStatus = false;
    this.menuPrivadoStatusChange.emit(this.menuPrivadoStatus);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
     if (!this.elementRef.nativeElement.contains(event.target)) {
     this.closeMenu();
     }
  }

}

