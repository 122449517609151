<div class="bs-contenedor" style="min-width: 90%;">
    <!-- <div class="row">
        <div class="col-12">
            <span class="bs-chip">Derivar Pines</span>
            <div class="bs-separador h-30"></div>
        </div>
    </div> -->

    <div class="bs-pestanas">
        <p-tabView>

            <p-tabPanel header="Derivar pines">
                <ng-template pTemplate="content">
                    <div class="bs-separador h-30"></div>
                    <div class="col-12">
                        <div class="bs-tabla-datos">
                            <p-table [value]="elementos_tabla_derivados"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} datos"
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th id="idSolicitudCreacionPines" rowspan="2" width="110"
                                            pSortableColumn="idSolicitudCreacionPines" hidden scope="col">
                                            <span>ID</span>
                                            <p-sortIcon field="idBolsaDistribucionPines"></p-sortIcon>
                                        </th>
                                        <th id="idBolsaDistribucionPines" rowspan="2" width="110"
                                            pSortableColumn="idBolsaDistribucionPines" hidden scope="col">
                                            <span>IdBolsa</span>
                                            <p-sortIcon field="idBolsaDistribucionPines"></p-sortIcon>
                                        </th>
                                        <th id="usuario" rowspan="2" width="110" pSortableColumn="usuario" scope="col">
                                            <span>Usuario</span>
                                            <p-sortIcon field="usuario"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="110" pSortableColumn="cantidad" scope="col">
                                            <span>Accion</span>
                                            <p-sortIcon field="cantidad"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                                            <span>Sitio Web</span>
                                        </th>
                                        <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                                            <span>Cantidad</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="110" pSortableColumn="etiqueta" scope="col">
                                            <span>Descuento</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="140" pSortableColumn="etiqueta" scope="col">
                                            <span>Vigencia Desde</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="140" pSortableColumn="etiqueta" scope="col">
                                            <span>Vigencia Hasta</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                        <th rowspan="2" width="20%" pSortableColumn="etiqueta" scope="col">
                                            <span>Acción</span>
                                            <p-sortIcon field="etiqueta"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-i="index">
                                    <tr>
                                        <td hidden>{{ data.idSolicitudCreacionPines }}</td>
                                        <td hidden>{{ data.idBolsaDistribucionPines }}</td>
                                        <td>{{ data.usuario }}</td>
                                        <td>{{ data.accionNombre }}</td>
                                        <td>{{ data.sitioWeb }}</td>
                                        <td>{{ data.cantidad }}</td>
                                        <td>{{ data.descuento }}</td>
                                        <td>{{ data.vigenciaDesde }}</td>
                                        <td>{{ data.vigenciaHasta }}</td>
                                        <td>
                                            <div class="bs-tabla-panel" style="text-align: center;width: 100%;">
                                                <div *ngIf="!data.esDerivarPirConvenio">
                                                    <button class="bs-btn bs-btn-primary tabla"
                                                        [ngClass]="{'no-visible': !data.esDerivar}"
                                                        (click)="mostrar_modal_derivar('Derivar', data.idSolicitudCreacionPines, data.idBolsaDistribucionPines, data.cantidad, data.descuento)">
                                                        Derivar
                                                    </button>
                                                    <button class="bs-btn bs-btn-secondary tabla"
                                                        [ngClass]="{'no-visible': !data.esAsignar}"
                                                        (click)="mostrar_modal_derivar('Asignar', data.idSolicitudCreacionPines, data.idBolsaDistribucionPines, data.cantidad, data.descuento)">
                                                        Asignar
                                                    </button>
                                                </div>
                                                <div *ngIf="data.esDerivarPirConvenio" style="width: 100%;">
                                                    <button class="bs-btn bs-btn-secondary tabla"
                                                        *ngIf="data.esDerivarPirConvenio"
                                                        (click)="mostrar_modal_derivar_convenio(data.idBolsaDistribucionPines, data.cantidad, data.descuento)">
                                                        Asignar a convenio
                                                    </button>
                                                </div>
                                                <!--<a class="bs-btn bs-btn-secondary tabla"> Desactivar </a>-->
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>


                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>

    <p-dialog [(visible)]="modal_mostrar" [draggable]="false" [resizable]="false" [modal]="true"
        [dismissableMask]="true" class="bs-modal w-500">
        <ng-template pTemplate="header">
            <h2 class="bs-azul bs-center">
                <strong>{{modal_msj_principal}}</strong>
            </h2>
        </ng-template>
        <ng-template pTemplate="content">
            <p class="bs-center">
                <strong class="bs-negro">{{modal_msj_secundario}} </strong>
            </p>

            <div class="bs-separador h-10"></div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="simple">
                <button class="bs-btn col-12 col-md-6 col-lg-3 bs-btn-primary" type="reset"
                    [ngClass]="{'bs-btn-error': error_carga_inicial, 'bs-btn-verde': !error_carga_inicial}"
                    (click)="aceptar_modal()">{{modal_boton_nombre}}</button>
            </div>
        </ng-template>
    </p-dialog>
</div>

<p-dialog [(visible)]="modal_derivar" [resizable]="false" [modal]="true" [dismissableMask]="true" [draggable]="false"
    class="bs-modal w-680 compacto">
    <ng-template pTemplate="header">
        <h4 class="bs-azul"><strong>{{tipo_accion}} Pin</strong>
        </h4>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="bs-separador h-4 lineal th-1"></div>
        <div class="bs-bloque">
            <article class="padding-h-4">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="form-15" class="bs-forms">
                                <h5>Usuario Comercial</h5>
                                <p-dropdown formControlName="usuarioComercial" [filter]="true" id="tipocontacto"
                                    [options]="datos" [(ngModel)]="tipo_datos" name="tipocontacto"
                                    placeholder="Selecciona usuario" optionLabel="nombre" optionValie="id"
                                    [showClear]="false" emptyMessage="Sin Datos" [autoDisplayFirst]="false"
                                    filterPlaceholder="Buscar un item " emptyFilterMessage="Sin datos"></p-dropdown>
                                <div *ngIf="submitted && controlsFormErros.usuarioComercial.errors"
                                    class="invalid-feedback">
                                    <div class="message-invalide"
                                        *ngIf="controlsFormErros.usuarioComercial.errors.required">Campo Requerido</div>
                                </div>
                            </label>
                        </div>

                        <div class="col-12 col-sm-6">
                            <label for="form-15" class="bs-forms">
                                <h5>Cantidad</h5>
                                <p-dropdown formControlName="cantidad" id="cantidad" [options]="datos_cantidad"
                                    [(ngModel)]="tipo_cantidad" name="cantidad" placeholder="Selecciona cantidad"
                                    optionLabel="nombre" optionValie="id" [showClear]="false" emptyMessage="Sin Datos">
                                </p-dropdown>
                                <div *ngIf=" submitted && controlsFormErros.cantidad.errors" class="invalid-feedback">
                                    <div class="message-invalide" *ngIf="controlsFormErros.cantidad.errors.required">
                                        Campo Requerido</div>
                                </div>
                            </label>
                        </div>

                    </div>

                    <div class="row btn-right">
                        <button class="bs-btn bs-btn-primary col-12 col-md-6 col-lg-3"
                            [ngClass]="{'bs-btn-error': error_carga_inicial, 'bs-btn-verde': !error_carga_inicial}">
                            {{tipo_accion}}</button>
                    </div>
                </form>
            </article>

        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="modal_derivar_convenio" [resizable]="false" [modal]="true" [dismissableMask]="true"
    [draggable]="false" class="bs-modal w-680 compacto ovf">
    <ng-template pTemplate="header">
        <h4 class="bs-azul"><strong>Asignar pin - convenio</strong>
        </h4>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="bs-separador h-4 lineal th-1"></div>
        <div class="bs-bloque">
            <article class="padding-h-4">
                <form>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="form-15" class="bs-forms">
                                <h5>Convenio</h5>
                                <p-dropdown [filter]="true" id="idInterno" [options]="convenios" name="idInterno"
                                    [(ngModel)]="idConvenioActual" placeholder="Selecciona usuario"
                                    optionLabel="nombreConvenio" optionValie="idInterno" [showClear]="false"
                                    emptyMessage="Sin Datos" [autoDisplayFirst]="false"
                                    filterPlaceholder="Buscar un item " emptyFilterMessage="Sin datos"></p-dropdown>
                                <div *ngIf="submitted && controlsFormErros.usuarioComercial.errors"
                                    class="invalid-feedback">
                                    <div class="message-invalide"
                                        *ngIf="controlsFormErros.usuarioComercial.errors.required">Campo Requerido</div>
                                </div>
                            </label>
                        </div>

                        <div class="col-12 col-sm-6">
                            <label for="form-15" class="bs-forms">
                                <h5>Cantidad</h5>
                                <p-dropdown id="cantidadActual" [options]="datos_cantidad" [(ngModel)]="cantidadActual"
                                    name="cantidadActual" placeholder="Selecciona cantidad" optionLabel="nombre"
                                    optionValie="id" [showClear]="false" emptyMessage="Sin Datos"></p-dropdown>
                                <div *ngIf=" submitted && controlsFormErros.cantidad.errors" class="invalid-feedback">
                                    <div class="message-invalide" *ngIf="controlsFormErros.cantidad.errors.required">
                                        Campo Requerido</div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div class="row btn-right">
                        <button type="button" (click)="asignarPinesConvenio()"
                            class="bs-btn bs-btn-primary col-12 col-md-6 col-lg-3"
                            [ngClass]="{'bs-btn-error': error_carga_inicial, 'bs-btn-verde': !error_carga_inicial}">
                            Asignar</button>
                    </div>
                </form>
            </article>

        </div>
    </ng-template>
</p-dialog>