<!-- <nav class="bs-menu-module lateral" @menuAnimation> -->
<nav class="bs-menu-module lateral">

    <button class="close" (click)="closeMenu()"></button>
    <div class="separador h-10"></div>

    <ul class="menu">
        <li *ngFor="let seccion of seccionesModulos">
            <input type="checkbox" id="seccion_{{seccion.id}}" />
            <label class="parent" for="seccion_{{seccion.id}}">
                <figure>
                    <img src="assets/img/svg/icon-menu-02.svg" alt="Icon 01" />
                </figure>
                <span>{{seccion.nombreSeccionModulo}}</span>
            </label>

            <ul class="child child-p">
                <li *ngFor="let grupo of seccion.grupos">
                    <input type="checkbox" id="grupo_{{grupo.id}}" />
                    <label class="parent" for="grupo_{{grupo.id}}">
                        <span> {{grupo.nombreGrupoModulo}} </span>
                    </label>
                    <ul class="child">
                        <li *ngFor="let modulo of grupo.modulos">
                            <a class="link" href="{{modulo.urlModulo}}" style="text-decoration: none;">
                                <span> {{modulo.nombreModulo}} </span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>

    </ul>
</nav>