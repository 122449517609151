import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BolsaPinesServiceService {

  constructor(private readonly http: HttpClient) { }

  getBolsaPinesPorUsuario(id: any, estado: any) {
    return this.http.get<any>(
      environment.API + '/v1/BolsaPinesDistribucion/ListarBolsaDePinesPorUsuarios',
      {
        params: {
          IdUsuario: id,
          EstadoBolsa: estado,
        }
      })
  }

  async getDerivarPin(idSolicitudPin: any, cantidad: any, descuento: any, idUsuario: any, idBolsa: any) {
    return this.http.get<any>(
      environment.API + `/v1/BolsaPinesDistribucion/DerivarPin?IdSolicitudPin=${idSolicitudPin}&Cantidad=${cantidad}&Descuento=${descuento}&IdUsuario=${idUsuario}&IdDerivacion=${idBolsa}`).toPromise(); 
  }
}
