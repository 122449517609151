import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNGModule } from 'src/app/shared/components/prime-ng/prime-ng.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from 'src/app/components.module';
import { FormatRutPipe } from 'src/app/pipes/format-rut.pipe';
import { DerivarPinComponent } from './pages/derivar-pin/derivar-pin.component';
import { PinesAsignadosCotizadoresComponent } from './pages/pines-asignados-cotizadores/pines-asignados-cotizadores.component';
import { PinesAsignadosConvenioComponent } from './pages/pines-asignados-convenio/pines-asignados-convenio.component';
import { PinesAsignadosClienteComponent } from './pages/pines-asignados-cliente/pines-asignados-cliente.component';
import { ListaBitacoraComponent } from './components/lista-bitacora/lista-bitacora.component';
import { EditarPinComponent } from './components/editar-pin/editar-pin.component';



@NgModule({
  declarations: [
    DerivarPinComponent,
    PinesAsignadosCotizadoresComponent,
    PinesAsignadosConvenioComponent,
    PinesAsignadosClienteComponent,
    ListaBitacoraComponent,
    EditarPinComponent
  ],
  imports: [
    PrimeNGModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ComponentsModule
  ],
  exports: [
    FormatRutPipe
  ],
})
export class AdministracionPinModule { }
